import { useRef, useLayoutEffect, useContext } from "react";
import styled from "styled-components";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

import { ScaleContext } from "../../index";

import imgMake from "./img/make.png";
import imgPack from "./img/pack.png";
const valScale = 0.625
const Section = styled.div`
  width: 100%;
  height: 670px;
  background-color: #FFB8A8;
  position: relative;
  z-index:96 !important;

  .container-pack {
    width: 100%;
    height: 600px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(${valScale});
      transform-origin:left center;
    .packing {
      width: 1573px;
      height: 790px;
      background-image: url(${imgPack});
      background-repeat: no-repeat;
      position: absolute;
      left: 120px;
      opacity: 0;

    }
    .p1 {
      background-position: 0 0;
      background-image: url(${require("./img/1.png")});
      opacity: 1;
    }
    .p2 {
      background-position: -1574px 0;
    }
    .p3 {
      background-position: 0 -791px;
    }
    .p4 {
      background-position: -1574px -791px;
    }
    .p5 {
      background-position: 0 -1581px;
    }
    .p6 {
      opacity: 0;
      background-position: -1574px -1581px;
    }
  }

  .path {
    opacity: 1;
    position: absolute;
    left: 195px;
    top: 290px;
    z-index: 99;
    transform: scale(0.625);
  }
  .food {
    width: 200px;
    height: 120px;
    position: absolute;
    background-position: 0 0;
    background-image: url(${imgMake});
    transform: scale(0.48);
    opacity: 0;
    z-index: 9999 !important;
  }
`;

const Package = () => {
  const packingRef = useRef(null);
  const packRef = useRef(null);
  const foodRef = useRef(null);
  const pathRef = useRef(null);
  gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);
  const { scale } = useContext(ScaleContext);
  useLayoutEffect(() => {

    if (scale > 0) {
      let path = pathRef.current;
      let pathLength = path.getTotalLength();
      let food = foodRef.current;
      let pack = packRef.current;
      let packTime = gsap.timeline({
        scrollTrigger: {
          trigger: pack,
          scroller: '#dataline_wrap',
          start: `${3000 - 3000 * scale} top`,
          end: `${4000 - 3000 * scale} end`,
          scrub: true,
          pin: true,
          anticipatePin: 1,
          pinSpacer: true,
          pinReparent: true,
        }
      })
        .to(
          ".p2",
          {
            opacity: 1,
          },
          1
        )
        .to(
          ".p3",
          {
            opacity: 1,
          },
          1.5
        )
        .to(
          ".p4",
          {
            opacity: 1,
          },
          2
        )
        .to(
          ".p5",
          {
            opacity: 1,
          },
          2.5
        )
        .to(
          ".p6",
          {
            opacity: 1,
          },
          3
        );
      console.log(packTime.scrollTrigger)
      let pathTime = gsap
        .timeline({
          scrollTrigger: {
            trigger: pack,
            start: `${3360 - 3000 * scale} top`,
            end: `${3600- 3000 * scale} 50%`,
            scrub: 3,
            scroller: "#dataline_wrap",
          },
        })
        .to(
          food,
          {
            opacity: 1,
          },
          1
        )
        .to(food, {
          motionPath: {
            path: path,
            align: path,
            pathLength: pathLength,
            scrub: 3,
            alignOrigin: [0.5, 0.5],
            autoKill: true,
          },
          duration: 2,
          ease: "power3.inOut",
        })
        .to(
          food,
          {
            opacity: 0,
          },
          3
        )
        .to(
          '#inbox_box',
          {
            backgroundPosition: `0 -570px`,
            duration: 0,
          },
          3
        );

      ScrollTrigger.refresh();
    }
    return () => {
      // if(pathTime){
      //   pathTime.kill()
      // }
      // if(packTime){
      //   packTime.kill()
      // }
    };
  }, [scale]);

  return (
    <Section ref={packRef}>
      <div className="container-pack" ref={packingRef}>
        <div className="packing p1"></div>
        <div className="packing p2"></div>
        <div className="packing p3"></div>
        <div className="packing p4"></div>
        <div className="packing p5"></div>
        <div className="packing p6"></div>
      </div>

      <div className="path">
        <div className="food" ref={foodRef}></div>
        <svg width="200px" height="680px" viewBox="0 0 200 1020" version="1.1">
          <g
            id="2"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              transform="translate(-335.000000, -4002.000000)"
              stroke="#979797"
              strokeWidth="0"
            >
              <g transform="translate(335.703623, 4002.577083)">
                <path
                  d="M198.5,0.424229808 C66.4991625,50.7661671 0.498743719,390.291424 0.498743719,1019"
                  ref={pathRef}
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </Section>
  );
};
export default Package;
