import React, { useRef} from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { debounce } from "../components/utils";

import vBg from "../staic/vbg.png";

import peisong1 from "../staic/peisong1.png";
import peisong2 from "../staic/peisong2.mp4";
import peisong3 from "../staic/peisong3.mp4";
const Section = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex: 1;
  font-size: 24px;
  justify-content: center;
  cursor: pointer;

  .swiper {
    width: 1200px;
    height: 100%;
  }

  .swiper-item {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .img2 {
    width: auto;
    height: 525px;
  }
  .img1 {
    width: 1200px;
    height: 525px;
    object-fit: cover;
    border-radius: 26px;
  }
  .img3 {
    width: auto;
    height: 400px;
    padding: 150px 0;
  }
  p {
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }


  .v-container {
    width: 270px;
    height: 540px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(${vBg});
    overflow: hidden;
    padding: 23px 15px 20px; 

    video {
      width: 100%;
      height: 100%;
      margin: 50px;
      max-width: 100%;
      max-height: 680px;
      margin: 50px auto;
      cursor: pointer;
      object-fit: over;
      border-radius: 26px;
    }
  }
`;

const ProductPeisong = () => {
  const swiperRef = useRef(null);
  const navigate = useNavigate();
  const goNextPage = (e) => {
    const { activeIndex } = swiperRef.current;
    if (e.deltaY > 60) {
      if (activeIndex === 2) {
        navigate("/product/guiji");
        return;
      }
      swiperRef.current.slideTo(activeIndex + 1);
    }
    if (e.deltaY < -60) {
      if (activeIndex === 0) {
        navigate("/product/food");
        return;
      }
      swiperRef.current.slideTo(activeIndex - 1);
    }
  };
  return (
    <Section onWheel={debounce(goNextPage, 500)}>
      <Swiper
        loop={true}
        navigation={{
          prevEl: ".custom-prev-button",
          nextEl: ".custom-next-button",
        }}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
      >
        <SwiperSlide className="swiper-item">
          <img className="img1" src={peisong1} alt="" />
          <p>【吾点自营→吾点配送】自有配送车辆，转为此项目服务</p>
        </SwiperSlide>
        <SwiperSlide className="swiper-item ">
          <div className="v-container">
          <video className="img2" autoPlay loop>
              <source src={peisong2} type="video/mp4" />
              Your browser does not support the video tag.
            </video> 
          </div>
          <p>【吾点自营→配送】自主开发专项配送app，配送无忧</p>
        </SwiperSlide>
        <SwiperSlide className="swiper-item ">
          <div className="animal">
            <video className="img2" autoPlay loop>
              <source src={peisong3} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <p>【吾点配送】 全程保温配送，留住食品温度保障食品口感</p>
        </SwiperSlide>
      </Swiper>
      <img
        src={require("../staic/left.png")}
        className="pre"
        alt=""
        onClick={() => swiperRef.current.slidePrev()}
      />
      <img
        src={require("../staic/right.png")}
        alt=""
        className="next"
        onClick={() => swiperRef.current.slideNext()}
      />
    </Section>
  );
};
export default ProductPeisong;
