import { React, useRef, useEffect, useState, useLayoutEffect } from "react";
import styled from "styled-components";
import Order from "./productline/Order";
import Purchase from "./productline/Purchase";
import Make from "./productline/Make";
import Pack from "./productline/Pack";
import Deliver from "./productline/Deliver";
import Storage from "./productline/Storage";
import Service from "./productline/Service";
import Pick from "./productline/Pick";

const Section = styled.section`
  height: 670px;
  cursor: pointer;
  align-items: stretch;
  overflow-y: auto;
  width: 1200px;
  overflow-x: hidden;
  position: relative;

`;

const ProductLine = () => {
  const productLineRef = useRef(null);
  const [scrollTop, setScrollTop] = useState(0);

  useLayoutEffect(() => {
    const handleWheel = (event) => {
      event.preventDefault(); // 阻止默认的滚动行为
      const delta = Math.max(-1, Math.min(1, event.deltaY));
      const newScrollTop = scrollTop + delta * 30; // 每次滚动15px的高度
      const maxScrollTop =
        productLineRef.current.scrollHeight -
        productLineRef.current.clientHeight;
      if (newScrollTop < 0) {
        setScrollTop(0);
      } else if (newScrollTop > maxScrollTop) {
        setScrollTop(maxScrollTop);
      } else {
        setScrollTop(newScrollTop);
      }
    };

    const sectionElement = productLineRef.current;
    sectionElement.addEventListener("wheel", handleWheel);
    return () => {
      sectionElement.removeEventListener("wheel", handleWheel);
    };
  }, [scrollTop]);

  useEffect(() => {
    productLineRef.current.scrollTop = scrollTop;
  }, [scrollTop]);

  return (
    <Section className="product-line" ref={productLineRef} id="product_line">
      <Order />
      <Purchase />
      <Make id="#pro_make" />
      <Pack />
      <Deliver />
      <Storage />
      <Pick/>
      <Service /> 
    </Section>
  );
};
export default ProductLine;

// import { React, useRef, useEffect, useState } from "react";
// import styled from "styled-components";
// import * as PIXI from "pixi.js";
// import Order from "./productline/Order";
// import Purchase from "./productline/Purchase";

// const Section = styled.section`
//   width: 1200px;
//   height: 700px;
//   background: #f87;
//   display: flex;
//   justify-content: center;
//   cursor: pointer;
//   padding: 0;
//   margin: 0;
// `;

// const PixiWrapper = styled.div`
//   width: 1200px;
//   height: 700px;
//   max-width: 1200px;
//   overflow: scroll; /* 或者设置为 auto */
// `;

// const ProductLine = () => {
//   const wrapRef = useRef(null);
//   const [app, setApp] = useState(null);

//   useEffect(() => {
//     const newApp = new PIXI.Application({
//       width: wrapRef.current.clientWidth,
//       height: wrapRef.current.clientWidth * (9 / 16), // 根据宽度等比例计算高度
//       backgroundColor: 0x666666,
//       resolution: window.devicePixelRatio || 1,
//       autoDensity: true,
//     });
//     setApp(newApp);

//     const orderContainer = new Order(newApp);
//     newApp.stage.addChild(orderContainer);
//     const purchaseContainer = new Purchase(newApp);
//     purchaseContainer.y=orderContainer.height
//     console.log(orderContainer.height,'99')
//     newApp.stage.addChild(purchaseContainer);

//     // 将渲染画布放置在 DIV 元素内
//     wrapRef.current.appendChild(newApp.view);

//     const handleResize = () => {
//       const width = wrapRef.current.clientWidth;
//       const height = wrapRef.current.clientWidth * (9 / 16)*8.5;
//       newApp.renderer.resize(width, height); // 调整舞台大小
//       orderContainer.scale.set(width / 1920); // 缩放 Order 容器，保持和舞台等比例缩放
//       purchaseContainer.scale.set(width / 1920); // 缩放 Order 容器，保持和舞台等比例缩放

//     };

//     window.addEventListener("resize", handleResize);
//     handleResize();

//     return () => {
//       window.removeEventListener("resize", handleResize);
//       newApp.destroy(true);
//     };
//   }, []);

//   return (
//     <Section>
//       <PixiWrapper ref={wrapRef}></PixiWrapper>
//     </Section>
//   );
// };

// export default ProductLine;
