import React, { useRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import 'amfe-flexible';

import SwiperCore, {
  // Pagination,
  Keyboard,
  Scrollbar,
  Autoplay,
  Navigation as SwiperNavigation,
} from "swiper";
import "swiper/css";
// import "swiper/css/scrollbar";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
import "swiper/css/autoplay";

import { debounce } from "../components/utils";
import food1 from "../staic/food1.png";
import food2 from "../staic/food2.png";

SwiperCore.use([SwiperNavigation]);

const Section = styled.section`
  width: 100%;
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  cursor: pointer;

  .swiper {
    width: 1200px;

    .swiper-item {
      width: 100%;
      overflow: hidden;
    }
  }
  .swiper img {
    width: 100%;
    height: 525px;
    object-fit: cover;
    border-radius: 26px;
  }
  p.text {
    width: 100%;
    text-align: center;
    font-size: 2.4rem;
    margin-top: 3rem;
  }
  .item img {
    width: 250px;
    height: 150px;
  }
  .item-top {
    width: 100%;
    overflow-x: auto;

    .food-item {
      width: 200px;
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 160px;
        width: auto;
      }
    }
  }
  ol {
    margin-top: 40px;
  }
  ol li {
    line-height: 40px;
    font-size: 21px;
    font-weight: 400;
  }
  .item-bottom {
    .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 60px;
    }
  }
`;
const ProductFood = () => {
  const swiperRef = useRef(null);
  const navigate = useNavigate();
  const goNextPage = (e) => {
    const { activeIndex } = swiperRef.current;
    if (e.deltaY > 60) {
      if (activeIndex === 2) {
        navigate("/product/peisong");
        return;
      }
      swiperRef.current.slideTo(activeIndex + 1);
    }
    if (e.deltaY < -60) {
      if (activeIndex === 0) {
        navigate("/toc");
        return;
      }
      swiperRef.current.slideTo(activeIndex - 1);
    }
  };

  const foodArr = [
    "../staic/food/1.png",
    "../staic/food/2.png",
    "../staic/food/3.png",
    "../staic/food/4.png",
    "../staic/food/5.png",
    "../staic/food/6.png",
    "../staic/food/7.png",
    "../staic/food/8.png",
    "../staic/food/9.png",
    "../staic/food/10.png",
    "../staic/food/11.png",
    "../staic/food/12.png",
    "../staic/food/13.png",
    "../staic/food/14.png",
    "../staic/food/15.png",
  ];
  return (
    <Section onWheel={debounce(goNextPage, 300)}>
      <Swiper
        loop={true}
        navigation={{
          prevEl: ".custom-prev-button",
          nextEl: ".custom-next-button",
        }}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
      >
        <SwiperSlide className="swiper-item">
          <img src={food1} alt="" />
          <p className="text">【吾点自营→吾点餐品】工艺：纯手工现做，包装餐品口感品质</p>
        </SwiperSlide>
        <SwiperSlide className="swiper-item ">
          <img src={food2} alt="" />
          <p className="text">
          【吾点自营→吾点餐品】采购：采购专员每日新鲜采购，市场第一批新鲜原材料，保障原材料质量
          </p>
        </SwiperSlide>
        <SwiperSlide className="swiper-item  item3">
          <div className="item-top">
            <Swiper
              slidesPerView={5}
              centeredSlides={false}
              slidesPerGroupSkip={1}
              grabCursor={true}
              keyboard={{
                enabled: true,
              }}
              loop
              scrollbar={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 600, // 自动轮播间隔时间，单位为毫秒
                disableOnInteraction: false // 用户操作swiper后是否停止自动轮播，默认为true
              }}
              modules={[Keyboard,Autoplay , Scrollbar]}
            >
              {foodArr.map((item, i) => {
                return (
                  <SwiperSlide key={i} className="food-item">
                    <img src={require('../staic/food/'+(i+1)+'.png')} alt=""/>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <ol className="item-bottom">
            <div className="title">早餐介绍</div>
            <li>
              包子、馒头、烧麦粢饭油条、豆浆粥、粽子、蒸饺、饼等40多种单品组合几百种套餐价格合理实惠，能满足每种食量客户的早餐需求；
            </li>
            <li>产品每日新鲜现制，纯手工制作；</li>
            <li>
              原料采购：当天凌晨3点采购农贸市场第一批新鲜食材，现采先制，保障食物新鲜口感同时3天一轮换套餐菜单，客户选择多样化满足大部分需求；
            </li>
            <li>
              包装：套餐组合封闭无菌包装，套餐统一打包，客户用一手提走方便携带省时省力；
            </li>
          </ol>
        </SwiperSlide>
      </Swiper>
      <img
        src={require("../staic/left.png")}
        className="pre"
        alt=""
        onClick={() => swiperRef.current.slidePrev()}
      />
      <img
        src={require("../staic/right.png")}
        alt=""
        className="next"
        onClick={() => swiperRef.current.slideNext()}
      />
    </Section>
  );
};
export default ProductFood;
