import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import 'amfe-flexible';

const NavWrap = styled.nav`

  width: 100%;
  max-width: 100.4rem !important;
  margin: 0 auto;
  z-index: 999;

  .nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .nav-down {
    margin-bottom: 3rem;
  }
  .nav-item,
  .nav-item-light {
    width: 14rem;
    line-height: 4.4rem;
    height: 4.4rem;
    text-align: center;
    color: #af3c00;
    background-color: #fff;
    position: relative;
    border-radius: 2.8rem;
    font-size: 1.4rem;
  }

  .menu {
    width: 100%;
    text-align: center;
    left: 0;
    position: absolute;
    display: none;
    background-color: #dc4801;
    border-radius: 0 0 28px 28px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 400;
  }
  .menu-up {
    width: 100%;
    text-align: center;
    left: 0;
    position: absolute;
    display: none;
    font-weight: 400;
    background-color: #dc4801;
    color: rgba(255, 255, 255, 0.8);
    bottom: 4.4rem;
    border-radius: 28px 28px 0 0;
  }
  .nav-item:hover,
  .nav-item-light {
    color: #fff;
    background-color: #dc4801;
    cursor: pointer;
  }
  .multi:hover {
    border-radius: 28px 28px 0 0;
  }

  .multi-up:hover {
    border-radius: 0 0 28px 28px;
  }
  .menu-i:hover {
    font-weight: 600 !important;
    color: #fff !important;
  }
  .multi:hover *,
  .multi-up:hover * {
    display: block;
  }
`;

const Nav = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const navList = [
    {
      id: 0,
      name: "吾点即食新零售",
      to: "/business/",
      menu: [
        {
          name: "鲜采现做",
          to: "/business/xiancai",
        },
        {
          name: "智能取餐",
          to: "/business/qucan",
        },
        {
          name: "吾点数字化",
          to: "/business/shuzihua",
        },

      ],
    },
    {
      id: 1,
      name: "ToB：吾点即食",
      to: "/tob",
    },
    {
      id: 2,
      name: "TOC：吾点热食",
      to: "/toc",
    },
    {
      id: 3,
      name: "吾点自营",
      to: "/product/",
      menu: [
        {
          name: "吾点餐品",
          to: "/product/food",
        },
        {
          name: "吾点配送",
          to: "/product/peisong",
        },
        {
          name: "智能柜机",
          to: "/product/guiji",
        },
      ],
    },
    {
      id: 4,
      name: "数据链介绍",
      to: "/dataline",
    },
    {
      id: 5,
      name: "产品链介绍",
      to: "/productline",
    },
  ];

  let path = location.pathname;
  const menuClick = (id, menuI) => {
    navigate(menuI.to);
  };

  const navClick = (id, to) => {
    navigate(to);
  };
  return (
    <NavWrap>
      <div className={path === "/" ? "nav nav-up" : "nav nav-down"}>
        {navList.map((item) => {
          if (!item.menu) {
            return (
              <div
                className={item.to === path ? "nav-item-light" : "nav-item"}
                key={item.id}
                onClick={() => navClick(item.id, item.to)}
              >
                <div className="title">{item.name}</div>
              </div>
            );
          } else {
            return (
              <div
                className={
                  (item.to === path ||
                  item.menu.some((menuItem) => menuItem.to === path)
                    ? "nav-item-light "
                    : "nav-item") +
                  " " +
                  (path === "/" ? "multi-up" : "multi")
                }
                key={item.id}
              >
                <div className="title">
                  {item.menu.findIndex(
                    (menuItem) =>
                      JSON.stringify(menuItem.to) ===
                      JSON.stringify(location.pathname)
                  ) >= 0
                    ? item.menu[
                        item.menu.findIndex(
                          (menuItem) =>
                            JSON.stringify(menuItem.to) ===
                            JSON.stringify(location.pathname)
                        )
                      ].name
                    : item.name}
                </div>
                <ul className={location.pathname === "/" ? "menu-up" : "menu"}>
                  {item.menu.map((menuItem) => (
                    <li
                      className={
                        menuItem.to === path ? "menu-i menu-i-light" : "menu-i"
                      }
                      key={menuItem.to}
                      onClick={() => menuClick(item.id, menuItem)}
                    >
                      {menuItem.name}
                    </li>
                  ))}
                </ul>
              </div>
            );
          }
        })}
      </div>
    </NavWrap>
  );
};
export default Nav;
