import { React, useRef, useLayoutEffect, useContext } from "react";
import styled from "styled-components";

import imgBg from "./img/bg.png";
import imgOrder from "./img/order.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { ScaleContext } from "../../index";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

const valScale = 0.625;
const Section = styled.section`
  width: 100%;
  height: 670px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-image: url(${imgBg});
  background-size: cover;
  z-index: 999;

  .pro-order > * {
    transform: scale(0.625);
    transform-origin: left top;
  }
  .pro-order {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(20, 60px);
    grid-template-rows: repeat(10, 67px);

    .title {
      width: 652px;
      height: 145px;
      background-image: url(${imgOrder});
      background-position: 0 0;
      grid-area: 2/2; /* 定位到第一行第一列，跨越两列 */
      z-index: 1;
    }

    .person {
      width: 529px;
      height: 653px;
      background-image: url(${imgOrder});
      background-position: 529px 0;
      grid-area: 4/1;
      z-index: 6;
    }
    .phone {
      width: 524px;
      height: 786px;
      background-image: url(${imgOrder});
    }
    .phone1 {
      background-position: 0 -657px;
      grid-area: 3/9;
      z-index: 3;
    }
    .phone2 {
      background-position: -526px -657px;
      grid-area: 3/9;
      z-index: 4;
      opacity: 0;
    }
    .phone3 {
      background-position: -1082px -657px;
      grid-area: 2/10;
      z-index: 5;
      opacity: 0;
    }
    .plane1 {
      width: 205px;
      height: 247px;
      background-image: url(${imgOrder});
      background-position: -652px 0;
      grid-area: 2/13;
      z-index: 6;
      opacity: 0;
    }
    .plane2 {
      width: 205px;
      height: 247px;
      background-image: url(${imgOrder});
      background-position: -862px 0;
      grid-area: 2/14;
      z-index: 6;
      opacity: 0;
    }
    .plane3 {
      width: 115px;
      height: 247px;
      grid-area: 3/15;
      background-image: url(${imgOrder});
      background-position: -1069px 0;
      z-index: 6;
      opacity: 0;
    }
    .path {
      opacity: 0.4;
      z-index: 999;
      grid-area: 4/6;
      width: 1251px;
      height: 1030px;
      svg {
        scale: 0.625;
      }
    }
  }
`;

const Order = () => {
  const { scale } = useContext(ScaleContext);
  gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);
  const [
    proOrderWrapRef,
    proOrderRef,
    personRef,
    phone1Ref,
    phone2Ref,
    phone3Ref,
    plane1Ref,
    plane2Ref,
    plane3Ref,
    pathRef,
    pathAreaRef,
  ] = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  useLayoutEffect(() => {
    if (scale > 0) {
      let [proOrderWrap, proOrder, path, pathArea] = [
        proOrderWrapRef.current,
        proOrderRef.current,
        pathRef.current,
        pathAreaRef.current,
      ];
      let [person, phone2, phone3] = [
        personRef.current,
        phone2Ref.current,
        phone3Ref.current,
      ];
      let [plane1, plane2, plane3] = [
        plane1Ref.current,
        plane2Ref.current,
        plane3Ref.current,
      ];
      let eleInfo = proOrderWrap.getBoundingClientRect();
      let eStart = 0;
      let eEnd = "1340";
      let orderTime = gsap
        .timeline({
          scrollTrigger: {
            trigger: proOrderWrap,
            scroller: "#product_line",
            start: `${eStart} top`,
            end: `${eEnd} bottom`,
            scrub: 4,
            pin: true,
            anticipatePin: 1,
            pinSpacer: true,
            pinReparent: true,
          },
          invalidateOnRefresh: true, // 设置为true以避免动画位置偏差问题
        })
        .to(
          person,
          {
            x: 300,
            duration: 2,
          },
          1
        )
        .to(
          phone2,
          {
            opacity: 1,
            duration: 1,
          },
          3
        )
        .to(
          phone3,
          {
            opacity: 1,
            duration: 1,
          },
          4
        )
        .to(
          phone3,
          {
            x: 60,
            duration: 1,
          },
          5
        )
        .to(
          phone3,
          {
            opacity: 0,
            duration: 1,
          },
          6
        )
        .to(
          plane1,
          {
            opacity: 1,
            duration: 1,
          },
          6
        )
        .to(
          plane1,
          {
            x: 50,
            duration: 1,
          },
          7
        )
        .to(
          plane1,
          {
            opacity: 0,
            duration: 1,
          },
          8
        )
        .to(
          plane2,
          {
            opacity: 1,
            duration: 1,
          },
          8
        )
        .to(
          plane2,
          {
            y: -30,
            duration: 1,
          },
          9
        )
        .to(
          plane2,
          {
            opacity: 0,
          },
          10
        );
      let pathInfo = pathArea.getBoundingClientRect();
      let pathLength = path.getTotalLength();
      let pathTime = gsap
        .timeline({
          scrollTrigger: {
            trigger: proOrderWrap,
            scroller: "#product_line",
            start: `201px 15%`,
            end: `830px bottom`,
            scrub: 4,
          },
          invalidateOnRefresh: true, // 设置为true以避免动画位置偏差问题
        })
        .to(
          plane3,
          {
            opacity: 1,
          },
          1
        )
        .to(
          plane3,
          {
            motionPath: {
              path: path,
              align: path,
              pathLength: pathLength,
              scrub: 4,
              alignOrigin: [0.5, 0.5],
              start: {
                rotation: -30,
                opacity: 1,
                x: path.getPointAtLength(0).y - 5,
              },
              endAt: {
                opacity: 0,
              },
              autoKill: true,
            },
            duration: 3,
            ease: "power3.inOut",
          },
          1
        )
        .to(
          plane3,
          {
            opacity: 0,
            duration: 1,
          },
          5
        )
        .to(
          "#list",
          {
            opacity: 1,
          },
          6
        );
    }
    return () => {};
  }, [scale]);

  return (
    <Section ref={proOrderWrapRef}>
      <div className="pro-order" ref={proOrderRef}>
        <div className="title"></div>
        <div className="person" ref={personRef}></div>
        <div className="phone phone1" ref={phone1Ref}></div>
        <div className="phone phone2" ref={phone2Ref}></div>
        <div className="phone phone3" ref={phone3Ref}></div>
        <div className="plane1" ref={plane1Ref}></div>
        <div className="plane2" ref={plane2Ref}></div>
        <div className="plane3" ref={plane3Ref}></div>
        <svg
          width="1258px"
          height="1041px"
          viewBox="0 0 1258 1041"
          version="1.1"
          className="path"
          ref={pathAreaRef}
        >
          <g
            id="2"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              transform="translate(-417.000000, -315.000000)"
            >
              <path
                d="M1421,324 C1511.66667,309.333333 1584.66667,344.833333 1640,430.5 C1723,559 1676.5,979.5 848,1245 C635.666667,1311.33333 492.166667,1346.5 417.5,1350.5"
                ref={pathRef}
              ></path>
            </g>
          </g>
        </svg>
      </div>
    </Section>
  );
};

export default Order;

// import * as PIXI from "pixi.js";
// import imgBg from "./img/bg.png";

// class Order extends PIXI.Container {
//   constructor(newApp) {
//     super();
//     const background = new PIXI.Sprite(PIXI.Texture.from(imgBg));
//     this.addChildAt(background, 0);
//     this.width = newApp.screen.width;
//     this.height = newApp.screen.height;
//     // 初始化时触发一次 resize 事件
//     this.handleResize();

//     // 监听 PIXI 应用程序对象的 resize 事件
//     const app = this.parent;
//     if (app) {
//       app.on("resize", this.handleResize);
//     }
//   }

//   destroy(options) {
//     const app = this.newApp;
//     if (app) {
//       const stageWidth = app.screen.width;
//       // 设置容器的宽度为舞台的宽度
//       this.width = stageWidth;
//     }
//   }

//   handleResize() {
//     const app = this.newApp;
//     if (app) {
//       const stageWidth = app.screen.width;
//       // 设置容器的宽度为舞台的宽度
//       this.width = stageWidth;
//     }
//   }
// }

// export default Order;
