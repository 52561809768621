import React from "react";
import styled from "styled-components";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const NextContainer = styled.div`
  width: 100px;
  height: 100px;
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-top: 1rem;
  margin: 0 auto;
  margin-top: 6rem;
  position: fixed;
  bottom: 200px;
  right: 90px;
  z-index: 999 !important;
  .btn {
    height: 64px;
    text-align: center;
    font-size: 10px;
    cursor: pointer;
  }
  .back-circle {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
  }
  .circle-icon {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #fff;
  }
  .text {
    width: 100%;
    text-align: center;
    margin-top: 8px;
  }
`;

const Next = () => {
  let location = useLocation()
  const navigate = useNavigate();
  const goNext=()=>{
    switch(location.pathname) {
      case '/':
      navigate("/business/xiancai")
      break;
      case '/business/xiancai':
      navigate("/business/qucan")
      break;
      case '/business/qucan':
        navigate("/business/shuzihua")
      break;
      case '/business/shuzihua':
        navigate("/tob")
      break;
      case '/tob':
        navigate("/toc")
      break;
      case '/toc':
        navigate("/product/food")
      break;
      case '/product/food':
        navigate("/product/peisong")
      break;
      case '/product/peisong':
        navigate("/product/guiji")
      break;
      case '/product/guiji':
        navigate("/dataline")
      break;
      case '/dataline':
        navigate("/productline")
      break;
      default:
        navigate("/")
        break;
    }
  }
  return (
    <NextContainer onClick={goNext}>
      <div className="btn" >
        <div className="back-circle">
          <div className="circle-icon"></div>
        </div>
        <div className="text">下一页</div>
      </div>
    </NextContainer>
  );
};
export default Next;
