import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DataLineInfo from "./dataline/DataLineInfo";
import imgDl from "../staic/dl.png";

import Begin from "./dataline/Begin";
import Purchase from "./dataline/Purchase";
import Make from "./dataline/Make";
import Pack from "./dataline/Pack";
import InBox from "./dataline/InBox";
import Deliver from "./dataline/Deliver";

const Section = styled.section`
  width: 1200px;
  height: 670px;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: pointer;
  background-color: #ffb8a8;
/* 
.up {
  width: 30px;
  height: 30px;
  background: #f0f;
  position: absolute;
} */
.down {}
`;
const DataLine = () => {
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);
  function handleScroll(event) {
    const scrollDistance = event.target.scrollTop - scrollPosition;
    setScrollPosition(event.target.scrollTop);
  }
  useEffect(() => {
    function handleScroll(event) {
      const scrollDistance = 30;
      const scrollDirection = event.deltaY > 0 ? 1 : -1;
      document
        .getElementById("dataline_wrap")
        .scrollBy(0, scrollDirection * scrollDistance);
      event.preventDefault();
    }
    document
      .getElementById("dataline_wrap")
      .addEventListener("wheel", handleScroll, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, []);

  return (
    <Section id="dataline_wrap" handleScroll={handleScroll}>
      <Begin />
      <Purchase id="s2" />
      <Make />
      <Pack />
      <InBox />
      <Deliver />
    </Section>
  );
};
export default DataLine;
