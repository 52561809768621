import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Footer = styled.footer`
  width: 100px;
  height: 100px;
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-top: 1rem;
  margin: 0 auto;
  margin-top: 6rem;
  position: fixed;
  bottom: 120px;
  right: 90px;
  z-index:999 !important;

  .btn {
    height: 64px;
    text-align: center;
    font-size: 10px;
    cursor: pointer;

  }
  .back-circle {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
  }

  .circle-icon {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;

  }
  .btn:hover .circle-icon{
    transition: transform 0.5s;
    transform: rotate(180deg);
  }


  .text {
    width: 100%;
    text-align: center;
  }
`;
const Back = () => {
  const navigate = useNavigate();
  function goHome() {
    navigate("/", { replace: true });
    
  }

  return (
    <Footer>
      <div className="btn" onClick={goHome}>
        <div className="back-circle">
          <div className="circle-icon"></div>
        </div>
        返回
      </div>
    </Footer>
  );
};
export default Back;
