import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React from "react";
import { useRef, useLayoutEffect, useContext } from "react";
import styled from "styled-components";

import imgDeliver from "./img/deliver.png";
import imgCar from "./img/car.png";
import { ScaleContext } from "../../index";

const valScale= 0.625
const Section = styled.section`
  width: 100%;
  height:670px;
  background-color: #ffb8a8;
  .container-deliver {
    width: 100%;
    height: 670px;
    position: relative;
    display: flex;
    transform: scale(${valScale});
    .device {

      width: 501px;
      height: 571px;
      background-image: url(${imgDeliver});
      background-repeat: no-repeat;
      background-position: -501px 0;
      position: absolute;
      left: 217px;
      bottom: 140px;
    }

    .peisong {
      width: 365px;
      height: 496px;
      background-image: url(${imgDeliver});
      background-repeat: no-repeat;
      background-position: -537px -1415px;
      position: absolute;
      background-repeat: no-repeat;
      z-index: 9 !important;
      position: absolute;
      right: 0;
      bottom: 30px;
      transform: scale(0.7);
      transform-origin: bottom;
      opacity: 0;
    }
    .car {
      width: 1000px;
      height: 460px;
      background-image: url(${imgCar});
      background-repeat: no-repeat;
      background-position: -115px 0;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 99;
      bottom: -80px;
      left: -1000px;
    }
  }
`;

const Deliver = () => {
  const { scale } = useContext(ScaleContext);

  gsap.registerPlugin(ScrollTrigger);
  const deliverRef = useRef(null);
  const carRef = useRef(null);
  const peisongRef = useRef(null);
  const deviceRef = useRef(null);

  useLayoutEffect(() => {
    if(scale>0){

    let deliver = deliverRef.current;
    let peisong = peisongRef.current;
    let car = carRef.current;
    let device = deviceRef.current;

    let carTime = gsap
      .timeline({
        scrollTrigger: {
          scroller: "#dataline_wrap",
          trigger: deliver,
          start: `${5000-5000*scale} 40%`,
          bottom: `${5000-5000*scale} 20%`,
          scrub: true,
        },
      })
      .to(car, {
        x: 2380,
        duration: 3,
      })
      .to(car, {
        backgroundPosition: `0 0`,
        duration: 0,
      });

    let deliverTime = gsap
      .timeline({
        scrollTrigger: {
          scroller: "#dataline_wrap",
          trigger: deliver,
          start: `${5000-5000*scale}  top`,
          bottom: `${5800-5000*scale}  bottom`,
          scrub: true,
          pin:true,
          anticipatePin: 1,
          pinSpacer: true,
          pinReparent: true,
        },
      })
      .to(
        peisong,
        {
          opacity: 1,
          duration: 0.5,
        },
        0
      )
      .to(peisong, {
        x: -1300*valScale,
        duration: 3,
      })
      .set(
        peisong,
        {
          scaleX: -0.7,
          scaleY: 0.7,
          width: "172px",
          height: `496px`,
          backgroundPosition: `0 -1415px`,
          duration: 0,
        },
        3
      )
      .set(
        device,
        {
          backgroundPosition: `0 0`,
          duration: 0,
        },
        3
      )
      .set(
        peisong,
        {
          scaleX: 0.7,
          duration: 0,
        },
        3
      )
      .to(
        peisong,
        {
          x: 1300,
          duration: 3,
        },
        3.5
      )
      .to(
        peisong,
        {
          opacity: 0,
          duration: 0.3,
        },
        5.5
      )
      .set(
        car,
        {
          backgroundPosition: `-115px 0`,
          duration: 0,
        },
        5.6
      )
      .to(
        car,
        {
          x: 3000,
          duration: 2,
        },
        6
      )
      .to(
        device,
        {
          y: 180,
          x: 150,
          scale: 1.2,
          duration: 1,
        },
        6.5
      );
      ScrollTrigger.refresh();
    }

    return () => {
      // if (carTime) carTime.kill();
      // if (deliverTime) deliverTime.kill();
    };
  }, [scale]);

  return (
    <Section ref={deliverRef}>
      <div className="container-deliver">
        <div className="device" ref={deviceRef}></div>
        <div className="peisong" ref={peisongRef} />
        <div className="car" ref={carRef} />
      </div>
    </Section>
  );
};

export default Deliver;
