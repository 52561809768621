import {
  React,
  useRef,
  useLayoutEffect,
  useContext,
} from "react";
import styled from "styled-components";
import { ScaleContext } from "../../index";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

import imgMake from "./img/make.png";
import imgFood from "./img/food.png";

const valScale = 0.625;
const Section = styled.section`
  width: 100%;
  height: 670px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-color: #d4ecff;
  z-index: 97;

  .pro-make > * {
    transform: scale(${valScale});
    transform-origin: top left;
  }
  .pro-make {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(20, 60px);
    grid-template-rows: repeat(10, 67px);

    .title {
      width: 481px;
      height: 144px;
      background-image: url(${imgMake});
      background-position: -718px 0;
      grid-area: 2/2; /* 定位到第一行第一列，跨越两列 */
      z-index: 1;
    }
    .desk {
      width: 1751px;
      height: 425px;
      background-image: url(${imgMake});
      background-position: 0 -398px;
      grid-area: 5 / 2; /* 定位到第一行第一列，跨越两列 */
      z-index: 1;
    }
    .person1 {
      width: 210px;
      height: 345px;
      background-image: url(${imgMake});
      background-position: 0 0;
      grid-area: 3 / 8; /* 定位到第一行第一列，跨越两列 */
      margin-top: 20px;
      z-index: 2;
      opacity: 0;
    }
    .person2 {
      width: 229px;
      height: 345px;
      background-image: url(${imgMake});
      background-position: -215px 0;
      grid-area: 3 / 8; /* 定位到第一行第一列，跨越两列 */
      margin-top: 20px;
      z-index: 2;
      margin-left: -10px;
    }
    .person3 {
      width: 255px;
      height: 394px;
      background-image: url(${imgMake});
      background-position: -463px 0;
      grid-area: 3 / 8; /* 定位到第一行第一列，跨越两列 */
      margin-top: 20px;
      z-index: 2;
      opacity: 0;
    }
    .food {
      width: 95px;
      height: 88px;
      background-image: url(${imgFood});
      grid-area: 7/11; /* 定位到第一行第一列，跨越两列 */
      z-index: 10;
      transform: scale(0.1);
      opacity: 0;
      z-index: 99;
    }
    svg {
      width: 582px;
      height: 754px;
      opacity: 0.2;
      grid-area: 7 / 11; /* 定位到第一行第一列，跨越两列 */
      z-index: 99;
      margin-top: 10px;
      margin-left: -5px;
    }
  }
`;

const Make = () => {
  const { scale } = useContext(ScaleContext);
  gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

  const [makeRef, person1Ref, person2Ref, person3Ref, foodRef, pathRef] = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  useLayoutEffect(() => {
    let [make, person1, person2, person3, food, path] = [
      makeRef.current,
      person1Ref.current,
      person2Ref.current,
      person3Ref.current,
      foodRef.current,
      pathRef.current,
    ];

    if (scale > 0) {
      let eTop = 2680 - 2680 * scale;
      let eEnd =3350- 2680 * scale;
      let makeTime = gsap
        .timeline({
          scrollTrigger: {
            trigger: make,
            scroller: "#product_line",
            start: `${eTop} center`,
            end: `${eEnd} bottom`,
            scrub: 2,
            onToggle: () => {
              if (makeTime.isActive()) {
                personTime.pause();
              } else {
                personTime.play();
              }
            },
          },
        })
        .to(
          food,
          {
            opacity: 1,
            duration: 1,
          },
          3
        )
        .to(
          food,
          {
            scale: 0.5,
            duration: 1,
          },
          4
        )

      let personTime = gsap
        .timeline({ repeat: -1 })
        .to(person2, { duration: 0.8, opacity: 1 })
        .to(person3, { duration: 0.8, opacity: 1 })
        .to(person1, { duration: 0.8, opacity: 1 });
        personTime.pause();

      let pathLength = path.getTotalLength();
      let pathTime = gsap
        .timeline({
          scrollTrigger: {
            trigger: make,
            scroller: "#product_line",
            start: `${ 3000 - 2680* scale} 50%`,
            end: `${ 3550- 2680 * scale} 90%`,
            scrub:1,
          },
        })
        .to(
          food,
          {
            motionPath: {
              path: path,
              align: path,
              pathLength: pathLength,
              scrub: 2,
              alignOrigin: [0.5, 0.5],
              autoKill: true,
            },
            duration: 1,
            ease: "power3.inOut",
          },
          1
        )
        .to(food,{
          opacity:0
        },)
    }


    return () => {};
  }, [scale]);

  return (
    <Section ref={makeRef}>
      <div className="pro-make">
        <div className="title"></div>
        <div className="person1" ref={person1Ref}></div>
        <div className="person2" ref={person2Ref}></div>
        <div className="person3" ref={person3Ref}></div>
        <div className="desk"></div>
        <div className="food" ref={foodRef}></div>
        <svg
          width="582px"
          height="754px"
          viewBox="0 0 582 754"
          version="1.1"
          className="path"
        >
          <g
            id="2"
            stroke="none"
            stroke-width="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              transform="translate(-900.000000, -2842.000000)"
            >
              <path
                d="M902.5,2847 C1264.5,3016.66667 1456,3266.16667 1477,3595.5"
                ref={pathRef}
              />
            </g>
          </g>
        </svg>
      </div>
    </Section>
  );
};

export default Make;
