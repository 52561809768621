import { React, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { debounce } from "../components/utils";

import vShuzihua from "../staic/shuzihua.mp4";

const Section = styled.section`
  width: 100%;
  flex: 1;
  display: flex;
  overflow: hidden;
`;
const V1 = styled.video`
/*   width: auto;
  height: 760px; */
  width: 1200px;
  margin: 0 auto;
  cursor: pointer;
  object-fit: over;
`;
function BusinessShuzihua() {
  const navigate = useNavigate();
  const videoRef1 = useRef(null);
  const goNextPage = (e) => {
    if (e.deltaY > 60) {
      return navigate("/tob");
    }
    if (e.deltaY < -60) {
      return navigate("/business/qucan");
    }
    /* const video = videoRef.current;
    const currentTime = video.currentTime;
    if(e.deltaY>60 && currentTime>=15){
      return navigate('/tob')
    }
    if(e.deltaY < -60 && currentTime<0.5) {
      return navigate('/business/qucan')
    }
    const step =e.deltaY > 0 ? 1.5 : -1.5;
    video.currentTime = currentTime + step;
    e.preventDefault();
    console.log('视频时间',currentTime,step,e.deltaY)  */
  };

  return (
    <Section onWheel={debounce(goNextPage, 500)}>
      <V1
        ref={videoRef1}
        src={vShuzihua}
        type="video/mp4"
        autoPlay
        muted
        loop
      />
    </Section>
  );
}
export default BusinessShuzihua;
