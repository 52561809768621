import  {useRoutes} from 'react-router-dom'

// 组件
import Home from "../pages/Home";
import BusinessCaigou from "../pages/BusinessCaigou";
import BusinessQucan from "../pages/BusinessQucan";
import BusinessShuzihua from "../pages/BusinessShuzihua";
import ToB from "../pages/ToB";
import ToC from "../pages/ToC";
import DataLine from "../pages/DataLine";
import ProductFood from '../pages/ProductFood';
import ProductPeisong from '../pages/ProductPeisong';
import ProductGuiji from '../pages/ProductGuiji';
import DataLineInfo from '../pages/dataline/DataLineInfo'
import ProductLine from '../pages/ProductLine';

const PrivateRouter = () => {
    const element = useRoutes([
        {
          path: '/',
          element: <Home />
        },
        {
          path: 'business/xiancai',
          element: <BusinessCaigou />
        },
        {
          path: 'business/qucan',
          element: <BusinessQucan />
        },
        {
          path: 'business/shuzihua',
          element: <BusinessShuzihua />
        },
        {
          path: '/tob',
          element: <ToB />
        },
        {
          path: '/toc',
          element: <ToC />
        },
        {
          path: '/product/food',
          element: <ProductFood />
        },
        {
          path: '/product/peisong',
          element: <ProductPeisong />
        },
        {
          path: '/product/guiji',
          element: <ProductGuiji />
        },
        {
          path: '/dataline',
          element: <DataLine />
        },
        {
          path: '/dataline-info',
          element: <DataLineInfo />
        },
        {
          path: '/productline',
          element: <ProductLine />
        },
      ]);
    
      return element;
};
export default PrivateRouter;
