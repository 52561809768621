import { React, useRef, useLayoutEffect, useContext } from "react";
import styled from "styled-components";
import imgPurchase from "./img/purchase.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { ScaleContext } from "../../index";

const Section = styled.section`
  width: 100%;
  height: 670px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  background-color: #ffe5cc;
  z-index: 99;
  .pro-purchase > * {
    transform: scale(0.625);
    transform-origin: left top;
  }
  .pro-purchase {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(20, 60px);
    grid-template-rows: repeat(10, 67px);
    .title {
      width: 361px;
      height: 145px;
      background-image: url(${imgPurchase});
      background-position: -53px 0;
      grid-area: 2/14;
    }
    .list {
      width: 317px;
      height: 370px;
      background-image: url(${imgPurchase});
      background-position: -415px 0;
      grid-area: 2/3;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-top: 83px;
      padding-right: 30px;
      opacity: 0;
      .list-item {
        width: 60px;
        height: 48px;
        background-image: url(${imgPurchase});
        opacity: 0;
      }
    }
    .market {
      width: 684px;
      height: 525px;
      background-image: url(${imgPurchase});
      background-position: 0 -1491px;
      grid-area: 4/11;
    }
    .shopcar1 {
      width: 363px;
      height: 338px;
      background-image: url(${imgPurchase});
      background-position: 0 -748px;
      grid-area: 7/1;
    }
    .shopcar2 {
      width: 363px;
      height: 338px;
      background-image: url(${imgPurchase});
      background-position: -363px -748px;
      grid-area: 7/1;
      opacity: 0;
    }
    .shopcar3 {
      width: 363px;
      height: 338px;
      background-image: url(${imgPurchase});
      background-position: 0 -1120px;
      grid-area: 7/1;
      opacity: 0;
    }
    svg {
      grid-area: 10/4;
      height: 736px;
      opacity: 0.5;
    }
  }
`;

const Purchase = () => {
  const { scale } = useContext(ScaleContext);
  gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

  const [
    proPurchaseRef,
    listItem1Ref,
    listItem2Ref,
    listItem3Ref,
    shopcar1Ref,
    shopcar2Ref,
    shopcar3Ref,
    pathRef,
  ] = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  useLayoutEffect(() => {
    let [listItem1, listItem2, listItem3, proPurchase] = [
      listItem1Ref.current,
      listItem2Ref.current,
      listItem3Ref.current,
      proPurchaseRef.current,
    ];
    let [shopcar1, shopcar2, shopcar3, path] = [
      shopcar1Ref.current,
      shopcar2Ref.current,
      shopcar3Ref.current,
      pathRef.current,
    ];
    if (scale > 0) {
      let eTop = 1340 - 1340 * scale;
      let eEnd = 2680 - 1340 * scale;
      let purchaseTime = gsap
        .timeline({
          scrollTrigger: {
            trigger: proPurchase,
            scroller: "#product_line",
            start: `${eTop} top`,
            end: `${eEnd} bottom`,
            scrub: 4,
            pin: true,
            anticipatePin: 1,
            pinSpacer: true,
            pinReparent: true,
          },
          invalidateOnRefresh: true, // 设置为true以避免动画位置偏差问题
        })
        .to(
          shopcar1,
          {
            x: 160,
            duration: 2,
          },
          1
        )
        .to(
          shopcar2,
          {
            x: 160,
            duration: 2,
          },
          1
        )
        .to(shopcar3, {
          x: 160,
          duration: 2,
        })
        .to(
          shopcar1,
          {
            opacity: 0,
            duration: 1,
          },
          3
        )
        .to(
          shopcar2,
          {
            opacity: 1,
            duration: 1,
          },
          3
        )
        .to(
          listItem1,
          {
            opacity: 1,
            duration: 1,
          },
          3
        )
        .to(
          shopcar2,
          {
            x: 320,
            duration: 2,
          },
          4
        )
        .to(
          shopcar3,
          {
            x: 320,
            duration: 2,
          },
          4
        )
        .to(
          shopcar2,
          {
            opacity: 0,
            duration: 1,
          },
          6
        )
        .to(
          shopcar3,
          {
            opacity: 1,
            duration: 1,
          },
          6
        )
        .to(
          listItem2,
          {
            opacity: 1,
            duration: 1,
          },
          7
        )
        .to(
          shopcar3,
          {
            x: 630,
            duration: 2,
          },
          8
        )
        .to(
          listItem3,
          {
            opacity: 1,
            duration: 1,
          },
          10
        );

      let pathLength = path.getTotalLength();

      let pathTime = gsap
        .timeline({
          scrollTrigger: {
            trigger: proPurchase,
            scroller: "#product_line",
            start: `${2010 - 1340 * scale - 67} center`,
            end: `${2010 - 1340 * scale + 400} bottom`,
            scrub: 2,
          },
          invalidateOnRefresh: true, // 设置为true以避免动画位置偏差问题
        })
        .to(
          shopcar3,
          {
            motionPath: {
              path: path,
              align: path,
              pathLength: pathLength,
              scrub: 4,
              alignOrigin: [0.5, 1],
              start: pathLength / 2 / pathLength, // 修改为正确的语法
              autoKill: true,
            },
            duration: 2,
            ease: "power3.inOut",
          },
          1
        )
        .to(
          shopcar3,
          {
            scaleX: -0.625,
            scaleY: 0.625,
          },
          5
        )
        .to(
          shopcar3,
          {
            rotation: -70,
            duration: 1,
          },
          6
        )
        .to(
          shopcar3,
          {
            width: `408px`,
            height: `360px`,
            backgroundPosition: `0 -375px`,
            duration: 0,
            scale: 0.8,
            rotation: 0,
            transformOrigin: "bottom left",
          },
          7
        )
        .to(
          shopcar3,
          {
            width: `347px`,
            height: `155px`,
            backgroundPosition: `-12px -198px`,
            duration: 0,
            scale: 0.8,
          },
          8
        )
        .to(
          shopcar3,
          {
            y: 480,
            duration: 0.5,
            scale: 0.6,
          },
          9
        )
        .to(
          shopcar3,
          {
            x: 630,
            duration: 0.5,
            scale: 0.6,
          },
          10
        )
        .to(
          shopcar3,
          {
            x: 520,
            duration:0.5
          },
          10.5
        )
        .to(
          shopcar3,
          {
            duration: 0.5,
            scale: 0.1,
            opacity: 0,
          },
          11
        )
        
    }
    return () => {};
  }, [scale]);

  return (
    <Section ref={proPurchaseRef}>
      <div className="pro-purchase">
        <div className="title"></div>
        <div className="list" id="list">
          <div className="list-item" ref={listItem1Ref}></div>
          <div className="list-item" ref={listItem2Ref}></div>
          <div className="list-item" ref={listItem3Ref}></div>
        </div>
        <div className="market"></div>
        <div className="shopcar1" ref={shopcar1Ref}></div>
        <div className="shopcar2" ref={shopcar2Ref}></div>
        <div className="shopcar3" ref={shopcar3Ref}></div>
        <svg
          width="1421px"
          height="747px"
          viewBox="0 0 1421 747"
          version="1.1"
          className="path"
        >
          <g
            id="2"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              transform="translate(-353.000000, -1981.000000)"
            >
              <path
                d="M353.5,1986.5 L1442.5,1986.5 C1660.16667,2003.83333 1769,2116.66667 1769,2325 C1769,2533.33333 1661.5,2666 1446.5,2723"
                ref={pathRef}
              />
            </g>
          </g>
        </svg>
      </div>
    </Section>
  );
};

export default Purchase;
// import * as PIXI from "pixi.js";
// import imgBg from "./img/bg.png";

// class Purchase extends PIXI.Container {
//   constructor() {
//     super();

//     const graphics = new PIXI.Graphics();
//     graphics.beginFill(0xccafcc);
//     // graphics.drawRect(0, 0,newapp.screen.width, newapp.screen.width*0.5);

//     graphics.drawRect(0, 0, 1920, 1080);
//     graphics.endFill();
//     this.addChildAt(graphics, 0);

//     // 初始化时触发一次 resize 事件
//     this.handleResize();

//     // 监听 PIXI 应用程序对象的 resize 事件
//     const app = this.parent;
//     if (app) {
//       app.on("resize", this.handleResize);
//     }
//   }

//   // 在销毁容器实例时，移除之前添加的监听器
//   destroy(options) {
//     const app = this.parent;
//     if (app) {
//       app.off("resize", this.handleResize);
//     }
//     super.destroy(options);
//   }

//   handleResize() {
//     const app = this.parent;
//     if (app) {
//       const stageWidth = app.screen.width;
//       const stageHeight = app.screen.height;

//       // Calculate the required scale based on the parent div's width
//       const scale = Math.min(stageWidth / this.width, 1);

//       // Scale the container and all child elements accordingly
//       this.scale.set(scale);
//       this.width = this.width * scale;
//       this.height = this.height * scale;
//     }
//   }
// }

// export default Purchase;
