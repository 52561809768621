import { useEffect, useRef, useState, createContext } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import gsap from "gsap";
// 样式
import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
// 组件
import Nav from "./components/Nav";
import Next from "./components/Next";
import Back from "./components/Back";
import PrivateRoute from "./components/PrivateRoute";
import imgBg from "./staic/bg.png";
import imgWechat from "./staic/wechat.png";

export const ScaleContext = createContext({
  scale: 1,
  setScale: () => {},
});

const GlobalStyle = createGlobalStyle`
html {
  width: 100%;
  height: 100%;
  margin:0;
  padding:0;
  font-size: 10px !important;
  background-color: #000;
}
  body {
    width: 100%;
    height: 100%;
    color: #fff;
    padding: 0;
    margin: 0;
    font-size: 10px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
  }
  p,div,ul,ol,h3, img  {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    border: none;
  }
  .root {
    width: 100%;
    height: 100%;
    font-size: 10px !important;
  }
  .max-height {
    height: 100vh;
  }
  li {
    list-style: none;
  }

  /* 切换按钮样式 */
  img.pre,
  img.next {
    width: 3rem;
    height: 6.8rem;
    position: absolute;
    top: 36%;
    cursor: pointer;
    z-index: 99;
  }
  img.pre {
    left: -8rem;
  }
  img.next {
    right: -8rem;
  }
`;
const Bg = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${imgBg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: multiply;

  .container {
    height: auto;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    display: inline-block;
    width: 1920px;
    height: 950px;
    transform-origin: 0 0;
    position: absolute;
    left: 50%;
    display: flex;
    flex-direction: column;
  }
`;
const Header = styled.header`
  width: 100%;
  height: 5rem;
  font-weight: 500;
  align-items: center;
  text-align: center;
  z-index: 99;
  position: relative;

  h3 {
    width: 100%;
    font-weight: 500;
    font-size: 4.6rem;
    line-height: 5rem;
  }
  h3.size-small {
    font-weight: 500;
    font-size: 3.6rem;
  }
`;
const Conact = styled.div`
  height: 5rem !important;
  display: flex;
  align-items: center;
  position: absolute;
  right: 90px;
  cursor: pointer;
  z-index: 9999999 !important;
  position: absolute;
  top: 0;
  right: 90px;

  .icon-phone:hover ~ .conact-phone,
  .icon-wechat:hover ~ .conact-wechat {
    display: block;
  }
  .icon-phone:hover {
    display: none;
  }

  img {
    width: 4rem;
    height: 4rem;
    margin-left: 2rem;
  }
  .conact-wechat {
    width: 150px;
    height: 150px;
    border: 1px solid #fff;
    position: absolute;
    bottom: -160px;
    right: 0;
    border-radius: 12px 0 12px 12px;
    img {
      width: 130px;
      height: 130px;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }

  .conact-phone,
  .conact-wechat {
    display: none;
  }
  .conact-phone {
    font-size: 24px;
    line-height: 36px;
    border: 2px solid #fff;
    padding: 0 20px;
    border-radius: 26px;
  }
`;
function App() {
  const location = useLocation();
  const mainRef = useRef(null);
  const [scale, setScale] = useState(Number);
  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    if (location.pathname === "/") {
      gsap.to(".nav", { y: 800 });
      gsap.to(mainRef.current, { y: 120 });
      gsap.to(".header", { y: 120 });
    } else if (location.pathname !== "/") {
      gsap.to(".nav", { y: 120 });
      gsap.to(".header", { y: 90 });
      gsap.to(mainRef.current, { y: 150 });
    }
    handleScreenAuto();
  }, [location.pathname]);

  const handleScreenAuto = () => {
    const designDraftWidth = 1980; //设计稿的宽度
    const designDraftHeight = 932; //设计稿的高度
    const newScale =
      document.documentElement.clientWidth /
        document.documentElement.clientHeight <
      designDraftWidth / designDraftHeight
        ? document.documentElement.clientWidth / designDraftWidth
        : document.documentElement.clientHeight / designDraftHeight;
    //缩放比例
    setScale(newScale);
    document.querySelector(
      "#container"
    ).style.transform = `scale(${newScale}) translate(-50%)`;
  };

  return (
    <ScaleContext.Provider value={{ scale, setScale }}>
      <Bg id="bg">
        <div className="container" id="container" value={scale}>
          <Header className="header">
            <h3
              className={location.pathname === "/" ? "size-big" : "size-small"}
            >
              即食餐饮新零售
            </h3>
            <Conact className="conact">
              <img
                className="icon icon-phone"
                src={require("./staic/wx.png")}
                alt="加载失败"
              />
              <div className="conact-phone">400-600-8160</div>
              <img
                className="icon icon-wechat"
                src={require("./staic/phone.png")}
                alt="加载失败"
              />
              <div className="conact-wechat">
                <img src={imgWechat} alt="" />
              </div>
            </Conact>
          </Header>
          <Nav className="nav" />
          <div ref={mainRef}>
            <PrivateRoute />
          </div>
          <Next />
          {location.pathname !== "/" && <Back />}
        </div>
      </Bg>
    </ScaleContext.Provider>
  );
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router className="router">
    <GlobalStyle />
    <App />
  </Router>
);
