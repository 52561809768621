import { React, useRef, useLayoutEffect, useContext, useState } from "react";
import styled from "styled-components";

import imgStorage from "./img/storage.png";
import imgDeliver from "./img/deliver.png";
import { ScaleContext } from "../../index";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const valScale = 0.625;
const Section = styled.section`
  width: 100%;
  height: 670px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-color: #ffe5cc;
  .pro-service > * {
    transform: scale(0.625);
    transform-origin: left top;
  }
  .pro-service {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(20, 60px);
    grid-template-rows: repeat(10, 67px);

    .title {
      width: 524px;
      height: 127px;
      grid-area: 2/14;
      background-image: url(${imgStorage});
      background-position: 0 -1479px;
      background-repeat: no-repeat;
    }

    .staging {
      width: 858px;
      height: 567px;
      grid-area: 4/4;
      background-image: url(${imgStorage});
      background-position: 0 -674px;
      background-repeat: no-repeat;
      margin-top: 50px;
    }
    .device {
      width: 1728px;
      height: 732px;
      grid-area: 3/2;
      background-image: url(${imgStorage});
      background-position: 0 -732px;
      background-repeat: no-repeat;
      margin-top: 30px;
    }
    .device2 {
      width: 1728px;
      height: 730px;
      grid-area: 3/2;
      background-image: url(${imgStorage});
      background-position: 0 0;
      background-repeat: no-repeat;
      margin-top: 30px;
      opacity: 0;
    }
    .car {
      width: 941px;
      height: 490px;
      background-image: url(${imgDeliver});
      background-position: -115px -325px;
      grid-area:  4/16; 
      z-index: 9;
      transform: scaleX( -0.9 ) scaleY(0.9);
      transform-origin: center bottom;
      z-index: 20;
    }
    .person1 {
      width: 369px;
      height: 496px;
      grid-area: 5/2;
      background-position: -1229px  -324px;
      background-image: url(${imgDeliver});
      background-repeat: no-repeat;
      z-index: 9;
      opacity: 0;
    }
    .person2 {
      width: 170px;
      height: 496px;
      background-image: url(${imgDeliver});
      background-position: -1060px  -325px;
      grid-area: 5/16;
      transform:scale(0.65);
      z-index: 10;
      opacity: 0;
    }
  }
`;

const Storage = () => {
  const { scale } = useContext(ScaleContext);
  gsap.registerPlugin(ScrollTrigger);
  const [
    proStorageRef,
    person1Ref,
    person2Ref,
    device2Ref,
    carRef
  ] = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  useLayoutEffect(() => {
    if (scale > 0) {
      let [
        proStorage,
        car,
        person1,
        person2,
        device2,

      ] = [
        proStorageRef.current,
        carRef.current,
        person1Ref.current,
        person2Ref.current,
        device2Ref.current,
      ]

      let storageTime=gsap.timeline({
        scrollTrigger: {
          trigger:proStorage,
          scroller: "#product_line",
          start: `${5900-5900*scale} top`,
          end: `${7200-5900*scale} bottom`,
          pin: true,
          anticipatePin: 1,
          pinSpacer: true,
          pinReparent: true,
          scrub:1
        },
      })
      .to(car,{
        x:-1400,
        duration:2
      },1)
      .to(car,{
        width: `1051px`,
        backgroundPosition: `0 -325px`,
        duration:0
      },3)
      .to(person1,{
        opacity:1,
        duration:1
      },4)
      .to(person1,{
        x:700,
        duration:2
      },5)
      .to(device2,{
        opacity:1,
        duration:1
      } ,8 )
      .to(person1,{
        opacity:0,
        duration:0
      } ,9 ) 
      .to(person2,{
        opacity:1,
        duration:0
      } ,9 )   
      .to(person2,{
        x:-700,
        duration:1.5
      } ,9.5) 
      .to(person2,{
        opacity:0,
        duration:0
      } ,11) 
      .to(car,{
        width: `941px`,
        backgroundPosition: `-115px -325px`,
        duration:0
      } ,11 )    
      .to(car,{
        x:-2000,
        duration:2
      } ,11.5 )     
    }

    return () => {};
  }, [scale]);

  return (
    <Section ref={proStorageRef}>
      <div className="pro-service">
        <div className="title"></div>
        <div className="car" ref={carRef}> </div>
        <div className="person1" ref={person1Ref}></div>
        <div className="person2" ref={person2Ref}></div>
        <div className="device"></div>
        <div className="device2" ref={device2Ref}></div>
      </div>
    </Section>
  );
};

export default Storage;
