import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import SwiperCore, { Navigation as SwiperNavigation } from "swiper";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
// import { isWheelFn } from "../components/utils";
import { debounce } from "../components/utils";

SwiperCore.use([SwiperNavigation]);
const Section = styled.section`
  width: 100%;
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  cursor: pointer;

  .swiper {
    width: 1200px;
    .swiper-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        /* max-width: 150rem; */
        width: 120rem;
        /* height: 700px; */
        height: 52.5rem;
        object-fit: cover;
        border-radius: 2.6rem;
      }
      .item-text {
        margin-top: 3rem;
        line-height: 3.3rem;
        text-align: center;
      }
    }
  }
  p {
    margin-top: 2rem;
    font-size: 2.4rem;
  } 
`;

const BusinessCaigou = () => {
  const swiperRef = useRef(null);
  const navigate = useNavigate();


  const goNextPage = (e) => {
    const { activeIndex } = swiperRef.current;
    if (e.deltaY > 60) {
      if (activeIndex === 2) {
        navigate("/business/qucan");
        return;
      }
      swiperRef.current.slideTo(activeIndex + 1);
    }
    if (e.deltaY < -60) {
      if (activeIndex === 0) {
        navigate("/");
        return;
      }
      swiperRef.current.slideTo(activeIndex - 1);
    }
  };


  return (
    <Section onWheel={debounce(goNextPage, 400)}>
      {/* <div className="video">
       <V1
          ref={videoRef1}
          src="https://static-files-1256253817.cos.ap-shanghai.myqcloud.com/shuzihua.webm"
          type="video/webm"
          autoPlay
          muted
          loop
        /> 
      </div>
 */}
      <Swiper
        className="swiper"
        loop={true}
        navigation={{
          prevEl: ".custom-prev-button",
          nextEl: ".custom-next-button",
        }}
        mousewheel={true}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
      >
        <SwiperSlide className="swiper-item">
          <img src={require("../staic/caigou-1.png")} alt="" />
          <p className="text">
            【鲜采现做】移动互联网全数字化、全数据链、实时数据管控食品供应链
          </p>
        </SwiperSlide>
        <SwiperSlide className="swiper-item">
          <img src={require("../staic/caigou-2.png")} alt="" />
          <p className="text">【鲜采现做】原材料新鲜采购、现制现售、全程热链（无冷链）</p>
        </SwiperSlide>
        <SwiperSlide className="swiper-item">
          <img src={require("../staic/peisong1.png")} alt="" />
          <p className="text">【鲜采现做】网格配送、定时送达</p>
        </SwiperSlide>
        <SwiperSlide className="swiper-item">
          <img src={require("../staic/toc-02.png")} alt="" />
          <p className="text">【鲜采现做】柜机无接触取餐、即购即取、即取即得，无需等候</p>
        </SwiperSlide>
      </Swiper>

      <img
        src={require("../staic/left.png")}
        className="pre "
        alt=""
        onClick={() => {}}
      />
      <img
        src={require("../staic/right.png")}
        alt=""
        className="next"
        onClick={() => {}}
      />
    </Section>
  );
};
export default BusinessCaigou;

// import { React } from "react";
// import styled from "styled-components";
// import { useNavigate } from "react-router-dom";

// const Section = styled.section`
//   width: 1520px;
//   display: flex;
//   justify-content: space-between;
//   font-size: 21px;
//   line-height: 29px;
//   cursor: pointer;

//   .item {
//     position: relative;
//     cursor: pointer;
//     width: 365px;
//     height: 504px;
//   }
//   .item-shadow {
//     width: 100%;
//     height: 504px;
//     background: rgba(0, 0, 0, 0.3);
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 99;
//     font-weight: 500;
//     padding: 15px;
//     display: flex;
//     align-items: flex-end;
//   }
//   .item .item-shadow span {
//     min-height: 58px;
//   }
//   .item .item-shadow:hover {
//     box-shadow: -17px 17px 30px #000, 17px -17px 30px #000;
//     padding-bottom: 40px;
//   }
// `;

// function BusinessCaigou(props) {
//   const navigate = useNavigate();

//   const goNextPage = (e) => {
//     if (e.deltaY > 0) {
//       navigate("/business/qucan");
//     } else if (e.deltaY < 0) {
//       console.log('回退')
//       navigate(-1);
//     }
//   };
//   return (
//     <Section onWheel={goNextPage}>
//       <div className="item">
//         <img src={require("../staic/business1-1.png")} alt="" />
//         <div className="item-shadow">
//           ●移动互联网全数字化、全数据 链、实时数据管控食品供应链
//         </div>
//       </div>
//       <div className="item">
//         <img src={require("../staic/business1-2.png")} alt="" />
//         <div className="item-shadow">
//           ●原材料新鲜采购、现制现售、全程热链（无冷链）
//         </div>
//       </div>
//       <div className="item">
//         <img src={require("../staic/business1-3.png")} alt="" />
//         <div className="item-shadow ">
//           <span> ●网格配送、定时送达</span>
//         </div>
//       </div>
//       <div className="item">
//         <img src={require("../staic/business1-4.png")} alt="" />
//         <div className="item-shadow">
//           ●柜机无接触取餐、即购即取、即取即得，无需等候
//         </div>
//       </div>
//     </Section>
//   );
// }
// export default BusinessCaigou;
