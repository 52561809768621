import { React, useRef, useLayoutEffect, useContext} from "react";
import styled from "styled-components";

import imgService from "./img/service.png";
import { ScaleContext } from "../../index";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const valScale = 0.625;
const Section = styled.section`
  width: 100%;
  height: 670px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-color: #ffe5cc;

  .pro-service > * {
    transform: scale(0.625);
    transform-origin: left top;
  }
  .pro-service {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(20, 60px);
    grid-template-rows: repeat(10, 67px);

    .title {
      width: 524px;
      height: 127px;
      grid-area: 2/14;
      background-image: url(${imgService});
      background-position: 0 -1256px;
      background-repeat: no-repeat;
    }
    .bubble {
      width: 254px;
      height: 208px;
      grid-area: 5/8;
      background-image: url(${imgService});
      background-position: 0 0;
      background-repeat: no-repeat;
    }
    .staging {
      width: 858px;
      height: 567px;
      grid-area: 4/4;
      background-image: url(${imgService});
      background-position: 0 -675px;
      background-repeat: no-repeat;
      margin-top: 50px;
    }
    .phone {
      width: 344px;
      height: 673px;
      grid-area: 3/13;
      background-image: url(${imgService});
      background-position: -380px 0;
      background-repeat: no-repeat;
      margin-top: 50px;
    }
    .person {
      width: 120px;
      height: 272px;
      grid-area: 7/15;
      background-image: url(${imgService});
      background-position: -255px 0;
      background-repeat: no-repeat;
      margin-top: 30px;

    }
    .line {
      width: 1236px;
      height: 18px;
      border-radius: 2px;
      grid-area: 10/4;
      margin-left: 20px;
      background: #111c4b;
    }
    
  }
`;

const Service = () => {
  const { scale } = useContext(ScaleContext);
  gsap.registerPlugin(ScrollTrigger);
  const [serviceRef,bubbleRef] = [useRef(null),useRef(null)];
  useLayoutEffect(() => {

    if (scale > 0) {
      let [service,bubble] = [serviceRef.current,bubbleRef.current]
      let serviceTime = gsap.timeline({
        scrollTrigger:{
          trigger: service,
          scroller: "#product_line",
          start: `${8000-8000*scale} top`,
          end: `${8670-8000*scale} bottom`,
          scrub: 2,
          pin: true,
          anticipatePin: 1,
          pinSpacer: true,
          pinReparent: true,
        }
      })
      .to(bubble,{
        x:-180,
        y:-120,
        duration:1
      })
    }

    return () => {};
  }, [scale]);

  return (
    <Section ref={serviceRef}>
      <div className="pro-service">
        <div className="title"></div>
        <div className="bubble" ref={bubbleRef}></div>
        <div className="staging"></div>
        <div className="phone"></div>
        <div className="person"></div>
        <div className="line"></div>
      </div>
    </Section>
  );
};

export default Service;
