import React, { useRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { debounce } from "../components/utils";

const Section = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex: 1;
  justify-content: center;
  cursor: pointer;
  .swiper {
    width: 1200px;
    height: 100%;
  }
  .swiper-item {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    font-size: 24px;
    padding-top: 50px;
  }
  .item-left {
    width: 691px;
  }
  .item-left img {
    width: 100%;
    height: auto;
  }

  .item-video {
    width: 370px;
    height: 614px;
  }
  .item-right {
    max-width: 680px;
    padding-bottom: 200px;
    .title {
      font-weight: 600;
      line-height: 60px;
    }
    li {
      margin-top: 15px;
    }
  }


`;

function ProducGuiji() {
  const swiperRef = useRef(null);
  const navigate = useNavigate();

  const goNextPage = (e) => {
    const { activeIndex } = swiperRef.current;
    if (e.deltaY > 60) {
      if (activeIndex === 1) {
        navigate("/dataline");
        return;
      }
      swiperRef.current.slideTo(activeIndex + 1);
    }
    if (e.deltaY < -60) {
      if (activeIndex === 0) {
        navigate("/puroduct/peisong");
        return;
      }
      swiperRef.current.slideTo(activeIndex - 1);
    }
  };
  return (
    <Section onWheel={debounce(goNextPage, 300)}>
      <Swiper
        loop={true}
        navigation={{
          prevEl: ".custom-prev-button",
          nextEl: ".custom-next-button",
        }}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
      >
        <SwiperSlide className="swiper-item">
          <div className="item-left">
            <img
              className=""
              src={require("../staic/product-device.png")}
              alt="吾点热食柜机"
            />
          </div>
          <ol className="item-right">
            <div className="title">【吾点自营→柜机】柜机介绍</div>
            <li>
              高效环保，智能控温50°-60°，不破坏食品营养成分、不变色。不改变口味；
            </li>
            <li>45格规格符合大部分酒店早餐需求；</li>
            <li>占地仅需1平米 ；</li>
            <li>个性化人机交互、自助预约下单，扫码即可下单与取餐；</li>
            <li>
              环保节源设计，加热功率只有1.2kw/小时加热时间只需15分钟，待机功
              率只有0.04kw，一天仅需1度电；
            </li>
          </ol>
        </SwiperSlide>
        <SwiperSlide className="swiper-item ">
          <video controls className="item-video">
            <source src={require("../staic/dc.mp4")} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <ol className="item-right">
            <div className="title">线上小程序</div>
            <li>1、专业技术团队为所有线上系统作技术支持；</li>
            <li>2、微信小程序一键自助预约下单；</li>
            <li>3、二维码反扫取餐 仅需1秒；</li>
            <li>4、客户取餐全程无需等待、无需排队；</li>
          </ol>
          <video controls className="item-video">
            <source src={require("../staic/qc.mp4")} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </SwiperSlide>
      </Swiper>
      <img
        src={require("../staic/left.png")}
        className="pre"
        alt=""
        onClick={() => swiperRef.current.slidePrev()}
      />
      <img
        src={require("../staic/right.png")}
        alt=""
        className="next"
        onClick={() => swiperRef.current.slideNext()}
      />
    </Section>
  );
}
export default ProducGuiji;
