import { useRef, useLayoutEffect ,useContext} from "react";
import styled from "styled-components";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

import imgCar from "./img/car.png";
import imgDeliver from "./img/deliver.png";
import imgScreen from "./img/deliver-screen.png";
import imgBox from "./img/box.png";
import { ScaleContext } from "../../index";


const valScale = 0.625;
const Section = styled.div`
  width: 100%;
  height: 670px;
  background-color: #ffb8a8;
  z-index: 95 !important;
  .container-inbox {
    width: 100%;
    height: 600px;
    position: relative;
    display: flex;
    transform: scale(${valScale});
    transform-origin: center;
      
    .screen {
      width: 255px;
      height: 354px;
      position: absolute;
      bottom: 360px;
      right: 300px;
      background-image: url(${imgScreen});
      background-repeat: no-repeat;
      background-position: 0 0;
    }
    .box {
      width: 380px;
      height: 325px;
      position: absolute;
      left: -70px;
      top: -85px;
      background-image: url(${imgBox});
      background-repeat: no-repeat;
      background-position: 0 0;
      transform: scale(0.8);
    }
    .person {
      width: 170px;
      height: 496px;
      background-image: url(${imgDeliver});
      background-position: 0 -1415px;
      position: absolute;
      z-index: 9 !important;
      position: absolute;
      background-repeat: no-repeat;
      bottom: 0px;
      left: -200px;
    }
    .car {
      width: 1000px;
      height: 460px;
      background-image: url(${imgCar});
      background-repeat: no-repeat;
      position: absolute;
      z-index: 9;
      bottom: 0;
      right: -600px;
    }
  }

`;

const InBox = () => {
  gsap.registerPlugin(ScrollTrigger);
  const { scale } = useContext(ScaleContext);

  const inboxRef = useRef(null);
  const personRef = useRef(null);
  const carRef = useRef(null);
  const boxRef = useRef(null);
  const screenRef = useRef(null);

  useLayoutEffect(() => {
    if(scale>0){
      let eTop =4000-scale*4000
      let eEnd =5000-scale*4000

    let inbox = inboxRef.current;
    let person = personRef.current;
    let car = carRef.current;
    let screen = screenRef.current;
    let box = boxRef.current;
    let inboxTime = gsap
      .timeline({
        scrollTrigger: {
          trigger: inbox,
          scroller: "#dataline_wrap",
          start: `${eTop} top`,
          end: `${eEnd} bottom`,
          scrub: 2,
          pin:true,
          anticipatePin: 1,
          pinSpacer: true,
          pinReparent: true,
        },
      })
      .to(
        box,
        {
          backgroundPosition: `-537px -1415px`,
          duration: 0,
        },
        2
      )
      .to(
        person,
        {
          x: 260,
          duration: 1,
        },
        1
      )
      .to(
        box,
        {
          backgroundPosition: `0 -325px`,
          height: `247px`,
          duration: 0,
        },
        2
      )
      .to(
        box,
        {
          opacity: 0,
          duration: 0.3,
        },
        3
      )
      .to(
        person,
        {
          width: "365px",
          backgroundPosition: `-172px -1415px`,
          duration: 0,
        },
        3.5
      )
      .to(
        person,
        {
          x: 1500,
          duration: 1.5,
        },
        3.8
      )
      .from(
        screen,
        {
          opacity: 0,
          duration: 0.5,
        },
        4
      )
      .to(
        person,
        {
          opacity: 0,
          duration: 0,
        },
        5.5
      )
      .to(
        car,
        {
          backgroundPosition: `-114px 0`,
          duration: 0,
        },
        5.8
      )
      .to(
        car,
        {
          x: 1000,
          duration: 2,
        },
        6
      );
      console.log(inboxTime.scrollTrigger)
    
    }
    return () => {
      // if (inboxTime) {
      //   inboxTime.kill();
      // }
    };
  }, [scale]);

  return (
    <Section ref={inboxRef}>
      <div className="container-inbox">
        <div className="screen" ref={screenRef}></div>
        <div className="box" id="inbox_box" ref={boxRef}></div>
        <div className="person" ref={personRef}></div>
        <div className="car" ref={carRef}></div>
      </div>
    </Section>
  );
};
export default InBox;
