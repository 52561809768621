import { React } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { debounce } from "../components/utils";

const Section = styled.section`
  width: 100%;
  flex: 1;
  display: flex;
  position: relative;
  font-size: 2.4rem;
  letter-spacing: 2px;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .video {
    width: 1200px;
    overflow: hidden;
  }
`
const V1 = styled.video`
  width: 1200px;
  max-width: 1200%;
  max-height: 600px;
  margin: 0 auto;
  cursor: pointer;
  object-fit: over;
`;
const BusinessQucan = () => {
  const navigate = useNavigate()
  const goNextPage = (e) => {
    if (e.deltaY > 60) {
      navigate("/tob");
    } else if (e.deltaY < 60) {
      // navigate('/business/xiancai');
      navigate('/business/shuzihua');
    }
  }
  return (
    <Section onWheel={debounce(goNextPage, 300)}>
      <div className="video">
       <V1
          src="https://static-files-1256253817.cos.ap-shanghai.myqcloud.com/wdjs.webm"
          type="video/webm"
          autoPlay
          muted
          loop
        /> 
      </div>
    </Section>

  );
}
export default BusinessQucan;
