import { useRef, useLayoutEffect, useContext } from "react";
import styled from "styled-components";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

import imgBg1 from "./img/bg1.jpg";
import imgPerson from "./img/person1.png";
import imgPhone from "./img/phone.png";
import imgPlane from "./img/plane.png";
import { ScaleContext } from "../../index";

const valScale = 0.625;
const Section = styled.section`
  height: 670px;
  background-image: url(${imgBg1});
  background-size: cover;
  background-position: center;
  position: relative;
  display: grid;
  grid-template-columns: repeat(20, 60px);
  grid-template-rows: repeat(10, 67px);
  z-index: 99 !important;

  div,svg {  
    transform: scale(${valScale});
    transform-origin: left top;
  }
  .person {
    width: 325px;
    height: 678px;
    background-image: url(${imgPerson});
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 326px, 679px;
    grid-area: 4/5;
  }
  .phone {
    width: 489px;
    height: 558px;
    position: relative;
    grid-area: 3/11;

    .p1 {
      width: 385px;
      height: 367px;
      background-image: url(${imgPhone});
      background-position: -387px;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 1;
    }
    .p2 {
      width: 385px;
      height: 367px;
      background-image: url(${imgPhone});
      background-position: 0;
      background-repeat: no-repeat;
      position: absolute;
      grid-area: 3/11;
    }
  }
  .plane {
    width: 209px;
    height: 249px;
    position: absolute;
    background-image: url(${imgPlane});
    background-repeat: no-repeat;
    background-position: 0 -373px;
    grid-area: 2/12;
    margin-top: 30px;
  }
  svg {
    width: 1162px;
    height: 1185px;
    grid-area: 3/5;
    z-index: 999;
  }
`;

const Begin = () => {
  const { scale } = useContext(ScaleContext);
  gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);
  const beginRef = useRef(null);
  const personRef = useRef(null);
  const phone1Ref = useRef(null);
  const phone2Ref = useRef(null);
  const planeRef = useRef(null);
  const pathRef = useRef(null);

  useLayoutEffect(() => {
    if (scale > 0) {
      console.log(scale, "begin");

      let begin = beginRef.current;
      let person = personRef.current;
      let phone1 = phone1Ref.current;
      let phone2 = phone2Ref.current;
      let plane = planeRef.current;
      let path = pathRef.current;
      let pathLength = path.getTotalLength();
      let beginTime = gsap
        .timeline({
          scrollTrigger: {
            trigger: begin,
            scroller: "#dataline_wrap",
            start: "0 top",
            end: "1340 bottom",
            scrub: 4,
            pin: true,
            anticipatePin: 1,
            pinSpacer: true,
            pinReparent: true,
          },
        })
        .fromTo(
          person,
          {
            backgroundPosition: `0 0`,
          },
          {
            backgroundPosition: `0 -679px`,
            duration: 0,
          },
          1
        )
        .fromTo(
          phone1,
          {
            scale: 0.1,
            opacity: 0,
            x: -400,
          },
          {
            opacity: 1,
            scale: 1,
            duration: 0.5,
            x: 0,
          },
          2
        )
        .to(
          phone1,
          {
            backgroundPosition: `-776px 0 `,
          },
          3
        )
        .fromTo(
          phone2,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 1,
          },
          4
        )
        .to(
          phone2,
          {
            x: -200 * valScale,
            opacity: 0,
            duration: 1,
          },
          4.5
        )
        .fromTo(
          plane,
          {
            scale: 0.3 * valScale,
            opacity: 0,
          },
          {
            scale: 1 * valScale,
            opacity: 1,
            x: 300 * valScale,
          },
          5
        )
        .set(
          plane,
          {
            width: `234px`,
            height: `268px`,
            backgroundPosition: `-209px -373px`,
            scale: valScale,
          },
          5.5
        )
        .set(
          plane,
          {
            width: `234px`,
            height: `268px`,
            backgroundPosition: `-209px -373px`,
            scale: valScale,
          },
          6.5
        );
      let planeTime = gsap
        .timeline({
          scrollTrigger: {
            trigger: begin,
            scroller: "#dataline_wrap",
            start: `130 10%`,
            bottom: `1300 50%`,
            scrub: 3,
          },
        })
        .to(
          plane,
          {
            motionPath: {
              path: path,
              align: path,
              pathLength: pathLength,
              scrub: 3,
              alignOrigin: [0.5, 0.5],
              startAt: {
                rotation: 15,
                y: path.getPointAtLength(0).y + 5,
              },
              autoKill: true,
            },
            duration: 3,
            ease: "power3.inOut",
          },
          1
        )
        .to(
          plane,
          {
            opacity: 0,
          },
          3
        )
        .to(
          "#list",
          {
            opacity: 1,
          },
          3
        );
      ScrollTrigger.refresh();
    }
    return () => {};
  }, [scale]);

  return (
    <Section ref={beginRef}>
      <div className="person" ref={personRef}></div>
      <div className="phone">
        <div className="p1" ref={phone1Ref}></div>
        <div className="p2" ref={phone2Ref}></div>
      </div>
      <div className="plane" ref={planeRef}></div>
      <svg width="1162px" height="1185px" viewBox="0 0 1162 1185" version="1.1">
        <g
          id="2"
          stroke="none"
          stroke-width="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            transform="translate(-466.000000, -265.000000)"
          >
            <g transform="translate(466.000000, 265.000000)">
              <path
                d="M1060.5,2.5 C1257.5,409.833333 1150.83333,703.166667 740.5,882.5 C330.166667,1061.83333 83.8333333,1161 1.5,1180"
                ref={pathRef}
              />
            </g>
          </g>
        </g>
      </svg>
    </Section>
  );
};
export default Begin;
