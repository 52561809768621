import { React, useState, useRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { debounce } from "../components/utils.js";
import { isPageBottom } from "../components/utils.js";
// import  Next  from "../components/Next";
import 'amfe-flexible';


const Section = styled.section`
  width: 1520px;
  margin: 0 auto;
  cursor: pointer;
  .top {
    font-size: 21px;
    line-height: 29px;
  }
  .con {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
    font-size: 2.1rem;
  }
  .con-left {
    width: 65rem;
    height: 60.2rem;
    margin-right: 3.8rem;
  }
  .con-right {
    flex: 1;
    li {
      margin-top: 2rem;
    }
    li span {
      color: #dc4801;
    }
    .right-contact {
      margin-top: 6.5rem;
    }
    .right-contact .title {
      font-size: 4rem;
    }
    .right-contact .info {
      display: flex;
      height: 6rem;
      margin-top: 2rem;
    }
    .right-contact .info-input {
      width: 53rem;
      height: 6rem;
      line-height: 6rem;
      font-size: 2.1rem;
      padding-left: 2.4rem;
      border: 2px solid #fff;
      box-sizing: border-box;
      color: #fff;
      background-color: rgba(255, 255, 255, 0);
      border-radius: 6rem 0 0 6rem;
    }
    .right-contact .info-btn {
      width: 116px;
      line-height: 6rem;
      color: #dc4801;
      background-color: #fff;
      border-radius: 0 6rem 6rem 0;
      text-align: center;
      font-weight: 600;
    }
  }
`;
const V1 = styled.video`
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  object-fit: over;
`;
const Home = (prop,ref) => {
  const navigate = useNavigate();
  const sectionRef = useRef(null);
  const [phone, setPhone] = useState("");

  const getPhone = (e) => {
    setPhone(e.target.value);
  };

  const phoneSubmit = () => {
    const isValid = /^1[3-9]\d{9}$/.test(phone);
    if (isValid) {
      let mobile = phone;
      fetch("https://dev.api.wdrs.cn/v1/wdrs-logs/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ mobile }),
      })
        .then((res) => {
          res.json();
          if (res.status === 200) {
            alert("提交成功");
          } else {
            alert("提交失败");
          }
        })
        .catch((error) => alert("提交失败"));
    } else {
      alert("请检查格式");
    }
  };

  const goNextPage = (e) => {
    if (e.deltaY > 60 && isPageBottom) {
      navigate("/business/xiancai");
    }
  };

  return (
    <Section onWheel={debounce(goNextPage, 600)} ref={sectionRef} >
      <div className="top">
        一家专注用数字化打造即食产业链，让日常餐饮回归本味，食品科技有限公司，也是集自主系统开发、制作、销售、配送、售后为闭环的餐饮供应链公司。
      </div>
      <div className="con">
        <div className="con-left">
          {/* <img src={require("../staic/business-1.png")} alt="" /> */}
          <V1
          src="https://static-files-1256253817.cos.ap-shanghai.myqcloud.com/wdjs.webm"
          type="video/webm"
          autoPlay
          muted
          loop
        /> 
        </div>
        <div className="con-right">
          <ul className="right-text">
            <li>餐饮行业“预制+冷链”模式正在背离消费者的真正需求。</li>
            <li>城市化进程使得日常餐饮便利性下降。</li>
            <li>
              市场供给于消费者需求的背离，揭示了一个
              <span>即食餐饮新零售</span>的广阔市场机会。
            </li>
            <li>
              移动互联网、云数据库技术成熟；智能手机普及；基础应用APP（微信小程
              序、线上支付、电子地图等）为人们广泛使用。
            </li>
            <li>
              移 动互联网、云数据库技术成熟；智能手机普及；基础应用APP（微信小程
              序、线上支付、电子地图等）为人们广泛使用。
            </li>
          </ul>
          <div className="right-contact">
            <div className="title">多叔（上海）食品科技有限公司</div>
            <div className="info">
              <input
                type=""
                className="info-input"
                name="phone"
                defaultValue=""
                placeholder="请输入您的手机号"
                onChange={getPhone}
              />
              <div className="info-btn" onClick={() => phoneSubmit()}>
                确认
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};
export default Home;
