import { useRef, useLayoutEffect, useContext } from "react";
import styled from "styled-components";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import imgBg2 from "./img/bg2.png";
import imgMarket from "./img/market.png";
import imgshopCar from "./img/shopcar.png";
import imgPlane from "./img/plane.png";

import { ScaleContext } from "../../index";

const valScal = 0.625;
const Section = styled.div`
  background-image: url(${imgBg2});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 670px;
  background-color: #ffba98;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 98;

  .container-pur {
    width: 100%;
    height: ${valScal * 600}px;
    position: relative;
    display: flex;
  }
  .market {
    width: ${686 * valScal}px;
    height: auto;
    position: absolute;
    right: 0;
    margin-right: ${valScal * 480}px;
  }
  .list {
    width: 314px;
    height: 372px;
    position: absolute;
    left: ${130 * valScal}px;
    background-image: url(${imgPlane});
    background-position: -51px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transform-origin: top left;
    opacity: 0;
    padding-top: 80px;
    transform: scale(${valScal});
    padding-right: 30px;
    .list-item {
      width: 50px;
      height: 48px;
      opacity: 0;
      background-image: url(${imgPlane});
    }
  }
  .path {
    width: 100%;
    height: 650px;
    position: absolute;
    bottom: -400px;
  }
  .shopcar {
    width: 363px;
    height: 338px;
    position: absolute;
    left: -200px;
    bottom: 0;
    background-image: url(${imgshopCar});
    background-repeat: no-repeat;
    background-position: -408px 0;
    transform: scale(0.5);
    z-index: 999 !important;
  }
`;

const Purchase = () => {
  gsap.registerPlugin(ScrollTrigger);
  const purchaseRef = useRef(null);
  const shopcarRef = useRef(null);
  const pathRef = useRef(null);
  const listRef = useRef(null);
  const { scale } = useContext(ScaleContext);

  useLayoutEffect(() => {
    if (scale > 0) {
      let shopcar = shopcarRef.current;
      let purchase = purchaseRef.current;
      let path = pathRef.current;
      let purchaseTime = gsap
        .timeline({
          scrollTrigger: {
            trigger: purchase,
            scroller: "#dataline_wrap",
            start: `${1340-1340*scale} top`,
            end: `${2010-1340*scale} bottom`,
            scrub: 3,
            pin: true,
            pinSpacing: true,
            pinReparent: true,

          },
        })
        .to(
          shopcar,
          {
            x: 300,
            duration: 2,
          },
          1
        )
        .to(
          shopcar,
          {
            backgroundPosition: `-771px 0`,
            duration: 0,
          },
          2.8
        )
        .to(
          ".list-item1",
          {
            opacity: 1,
          },
          3
        )
        .to(
          shopcar,
          {
            x: 700,
            duration: 3,
          },
          3
        )
        .to(
          ".list-item2",
          {
            opacity: 1,
          },
          4.5
        )
        .to(
          shopcar,
          {
            backgroundPosition: `-1135px 0`,
            scaleX: -1 * 0.5,
            duration: 0,
          },
          7
        )

        .to(
          ".list-item3",
          {
            opacity: 1,
          },
          6
        )
        .to(
          shopcar,
          {
            x: 1100,
            duration: 3,
          },
          6
        );
      let shopcarTime = gsap
        .timeline({
          scrollTrigger: {
            trigger: purchase,
            scroller: "#dataline_wrap",
            start: `${1640-1340*scale} top`,
            end: `${2010-1340*scale} 5%`,
            scrub: 3,
          },
        })
        .to(
          shopcar,
          {
            rotate: 90,
            duration: 1.5,
          },
          1
        )
        .to(
          shopcar,
          {
            y: 520,
            duration: 2,
          },
          2
        )
        .to(
          shopcar,
          {
            scaleX: 1 * 0.5,
            duration: 0.5,
          },
          4.5
        )
        .to(
          shopcar,
          {
            rotate: -45,
            duration: 0.5,
          },
          5
        )
        .to(
          shopcar,
          {
            x: 1560 * valScal,
            duration: 0.5,
          },
          6
        )
        .to(
          shopcar,
          {
            backgroundPosition: `0 0`,
            duration: 0.5,
          },
          6
        )
        .to(
          shopcar,
          {
            opacity: 0,
            duration: 0.3,
          },
          6.5
        )
        .to(".cai", { opacity: 1 }, 7)
        .to(".cai", { x: -100, y: -60, duration: 1 }, 8)
        .to(".cai", { opacity: 0, duration: 1 }, 9);
      ScrollTrigger.refresh();
    }
    return () => {
      // if (shopcarTime) {
      //   shopcarTime.kill();
      // }
    };
  }, [scale]);

  return (
    <Section ref={purchaseRef}>
      <div className="container-pur">
        <div className="list" id="list" ref={listRef}>
          <div className="list-item list-item1"></div>
          <div className="list-item list-item2"></div>
          <div className="list-item list-item3"></div>
        </div>
        <img className="market" src={imgMarket} alt="" />
      </div>
      <div className="shopcar" ref={shopcarRef}></div>
    </Section>
  );
};
export default Purchase;
