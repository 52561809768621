import { React, useRef, useLayoutEffect, useContext, useState } from "react";
import styled from "styled-components";

import imgStorage from "./img/storage.png";
import imgPick from "./img/pick.png";
import { ScaleContext } from "../../index";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const valScale = 0.625;
const Section = styled.section`
  width: 100%;
  height: 670px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-color: #ffe5cc;

  .pro-pick > * {
    transform: scale(0.625);
    transform-origin: left top;
  }
  .pro-pick {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(20, 60px);
    grid-template-rows: repeat(10, 67px);

    .title {
      width: 524px;
      height: 145px;
      grid-area: 2/2;
      background-image: url(${imgPick});
      background-position: -342px 0;
      background-repeat: no-repeat;
    }
    .person {
      width: 342px;
      height: 508px;
      grid-area: 5/1;
      background-image: url(${imgPick});
      background-position: 0 0;
      background-repeat: no-repeat;
      margin-top: 30px;
      z-index: 2;
    }
    .device {
      width: 1728px;
      height: 730px;
      grid-area: 3/2;
      background-image: url(${imgStorage});
      background-position: 0 0;
      background-repeat: no-repeat;
      margin-top: 30px;
    }
  }
`;

const Pick = () => {
  const { scale } = useContext(ScaleContext);
  gsap.registerPlugin(ScrollTrigger);
  const [pickRef, personRef] = [useRef(null), useRef(null)];
  useLayoutEffect(() => {
    if (scale > 0) {
      let [pick, person] = [pickRef.current, personRef.current];
      let pickTime = gsap
        .timeline({
          scrollTrigger: {
            trigger: pick,
            scroller: "#product_line",
            start: `${7200 - 7200 * scale} top`,
            end: `${8000 - 7200 * scale} bottom`,
            scrub: 2,
            pin: true,
            anticipatePin: 1,
            pinSpacer: true,
            pinReparent: true,
          },
        })
        .to(person, {
          x: 800,
          duration: 2,
        });
    }

    return () => {};
  }, [scale]);

  return (
    <Section ref={pickRef}>
      <div className="pro-pick">
        <div className="title"></div>
        <div className="person" ref={personRef}></div>
        <div className="device"></div>
      </div>
    </Section>
  );
};

export default Pick;
