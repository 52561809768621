import { React, useRef, useEffect, useLayoutEffect, useContext } from "react";
import styled from "styled-components";

import imgPack1 from "./img/pack1.png";
import imgPack2 from "./img/pack2.png";
import imgFood from "./img/food.png";
import { ScaleContext } from "../../index";
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";

const valScale = 0.625;
const Section = styled.section`
  width: 100%;
  height: 670px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-color: #ffe5cc;
  z-index: 96;

  .pro-pack > * {
    transform: scale(0.625);
    transform-origin: left top;
  }
  .pro-pack {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(20, 60px);
    grid-template-rows: repeat(10, 67px);

    .title {
      width: 530px;
      height: 130px;
      background-image: url(${imgPack1});
      background-position: -1303px -1327px;
      grid-area: 2/14;
    }
    .pack {
      width: 1300px;
      height: 654px;
      grid-area: 4/5;
    }
    .imgpack1 {
      background-image: url(${imgPack1});
    }
    .imgpack2 {
      background-image: url(${imgPack2});
    }
    .pack1,
    .pack4 {
      background-position: 0 0;
      opacity: 0;
    }
    .pack2,
    .pack5 {
      background-position: 0 -655px;
      opacity: 0;
    }
    .pack3,
    .pack6 {
      background-position: 0 -1308px;
      opacity: 0;
    }
    .pack2 {
      opacity: 1;
    }
    .path {
      width: 2px;
      height: 860px;
      background-color: #999;
      grid-area: 8/6;
      z-index: 99;
    }
    .food {
      width: 156px;
      height: 193px;
      background-image: url(${imgFood});
      grid-area: 8/5;
      background-position: -1px -92px;
      scale: calc(0.75);
      margin-left: 20px;
      opacity: 0;
    }
  }
`;

const Pack = () => {
  const { scale } = useContext(ScaleContext);
  gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);
  const [
    proPackRef,
    pack1Ref,
    pack2Ref,
    pack3Ref,
    pack4Ref,
    pack5Ref,
    pack6Ref,
    foodRef,
  ] = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  useLayoutEffect(() => {

    let [proPack, pack1, pack2, pack3, pack4, pack5, pack6, food] = [
      proPackRef.current,
      pack1Ref.current,
      pack2Ref.current,
      pack3Ref.current,
      pack4Ref.current,
      pack5Ref.current,
      pack6Ref.current,
      foodRef.current,
    ];

    if (scale > 0) {
      let eTop = 3350 - 3350 * scale;
      let eEnd = 4690 - 3350 * scale;
      let packTime = gsap
        .timeline({
          scrollTrigger: {
            trigger: proPack,
            scroller: "#product_line",
            start: `${eTop} top`,
            end: `${eEnd} bottom`,
            scrub: 4,
            pin: true,
            anticipatePin: 1,
            pinSpacer: true,
            pinReparent: true,
          },
          invalidateOnRefresh: true, // 设置为true以避免动画位置偏差问题
        })
        .to(
          pack1,
          {
            opacity: 1,
          },
          0
        )
        .to(
          pack2,
          {
            opacity: 1,
          },
          2
        )
        .to(
          pack3,
          {
            opacity: 1,
          },
          3
        )
        .to(
          pack4,
          {
            opacity: 1,
          },
          4
        )
        .to(
          pack5,
          {
            opacity: 1,
          },
          5
        )
        .to(
          pack6,
          {
            opacity: 1,
          },
          6
        );

      let pathTime = gsap
        .timeline({
          scrollTrigger: {
            trigger: proPack,
            scroller: "#product_line",
            start: `${eTop + 429} 20%`,
            end: `${eTop+600} 60%`,
            scrub: 2,
          },
        })
        .to(food, {
          opacity:1,
        },1)
        .to(food, {
          y: 560,
          duration: 2,
        },2)
        .to(
          food,
          {
            opacity: 0,
          },
          4
        )
        .to(
         "#pro_box_1",
          {
            opacity: 0,
          },
          4
        )
        .to(
          "#pro_box_2",
           {
             opacity: 1,
           },
           4
         )
    }
    return () => {};
  }, [scale]);

  return (
    <Section ref={proPackRef}>
      <div className="pro-pack">
        <div className="title"></div>
        <div className="pack imgpack1 pack2" ref={pack2Ref}></div>
        <div className="pack imgpack1 pack3" ref={pack3Ref}></div>
        <div className="pack imgpack2 pack4" ref={pack4Ref}></div>
        <div className="pack imgpack2 pack5" ref={pack5Ref}></div>
        <div className="pack imgpack2 pack6" ref={pack6Ref}></div>
        <div className="food" ref={foodRef}></div>
      </div>
    </Section>
  );
};

export default Pack;
