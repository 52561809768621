import { useRef, useLayoutEffect, useContext } from "react";
import styled from "styled-components";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import imgMake from "./img/make.png";
import imgCai from "./img/cai.png";
import { ScaleContext } from "../../index";

const valScale = 0.625;

const Section = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffb8a8;
  position: relative;
  height: 670px;
  z-index: 97 !important;



  .container-make {
    width: 1500px;
    height: 670px;
    position: relative;
    transform: scale(${valScale});
    transform-origin: center ;
    .desk {
      width: 1205px;
      height: 556px;
      position: absolute;
      top: 80px;
      background-image: url(${imgMake});
      background-position: 0 -440px;
      .computer {
        width: 586px;
        height: 440px;
        position: absolute;
      }
      .computer div {
        width: 586px;
        height: 440px;
        background-image: url(${imgMake});
        position: absolute;
        z-index: 999;
      }
      .computer1 {
        background-position: -298px 0;
        opacity: 0;
      }
      .computer2 {
        background-position: -888px 0;
        opacity: 0;
      }
      .computer3 {
        background-position: -1488px 0;
        opacity: 0;
      }
      .person {
        width: 228px;
        height: 340px;
        position: absolute;
        top: 100px;
        right: 100px;
        z-index: 999;
      }
      .person div {
        width: 228px;
        height: 340px;
        background-image: url(${imgMake});
        position: absolute;
      }
      .person1 {
        background-position: -1205px -440px;
        opacity: 1;
      }
      .person2 {
        background-position: -1661px -440px;
        opacity: 0;
      }
      .person3 {
        background-position: -1433px -440px;
        opacity: 0;
      }
      .cai {
        width: 200px;
        height: 104px;
        position: absolute;
        right: 30px;
        bottom: 30px;
        background-image: url(${imgCai});
        background-repeat: no-repeat;
        opacity: 0;
        z-index: 99999 !important;
      }
    }
  }


  .food {
    width: 95px;
    height: 89px;
    background-image: url(${imgMake});
    background-position: -203px 0;
    background-repeat: no-repeat;
    position: absolute;
    transform: scale(0.48);
    top: 400px;
    right: 320px;
    z-index: 999 !important;
  }
`;
const Make = () => {
  const { scale } = useContext(ScaleContext);
  const makeRef = useRef(null);
  gsap.registerPlugin(ScrollTrigger);
  const foodRef = useRef(null);

  useLayoutEffect(() => {


    if(scale>0) {
      let make = makeRef.current;
      let food = foodRef.current;

      let makeTime = gsap
      .timeline({
        scrollTrigger: {
          scroller: "#dataline_wrap",
          trigger: make,
          start: `${2010-scale*2010} top`,
          end: `${3000-scale*2010} bottom`,
          scrub: true,
          pin: true,
          pinReparent:true,
          pinSpacing:true,
          onToggle: () => {
            if (makeTime.isActive()) {
              personTime.pause();
            } else {
              personTime.play();
            }
          },
        },
        onComplete: () => {
          personTime.restart();
        },
      })
      .to(".computer1", { duration: 1, opacity: 1 }, 1)
      .to(".computer2", { duration: 1, opacity: 1 }, 2)
      .to(".computer3", { duration: 1, opacity: 1, x: 10 }, 3);

    let personTime = gsap
      .timeline({ repeat: -1 })
      .to(".person1", { duration: 1, opacity: 1 })
      .to(".person2", { duration: 1, opacity: 1 })
      .to(".person3", { duration: 1, opacity: 1 });
    personTime.pause();
    let foodTime = gsap
      .timeline({
        scrollTrigger: {
          trigger: make,
          scroller: "#dataline_wrap",
          start: `${2490-scale*2010} 50%`,
          end: `${2690-scale*2010} 50%`,
          scrub: 2,
        },
      })
      .fromTo(
        food,
        {
          opacity: 0,
          scale: valScale * 0.2,
          duration: 0.5,
        },
        {
          opacity: 1,
          x: 10,
          scale: valScale * 0.5,
          duration: 0.5,
        },
        0
      )
    .to(food,{
      x:116,
      y:395,
      duration:3
    },1)
    .to(food,{
      opacity:0,
      duration:0.5
    },5)
    ScrollTrigger.refresh();
    }


    return () => {
      // if (foodTime) {
      //   foodTime.kill();
      // }
    };
  }, [scale]);

  return (
    <Section ref={makeRef}>
      <div className="container-make">
        <div className="desk">
          <div className="computer">
            <div className="computer1"></div>
            <div className="computer2"></div>
            <div className="computer3"></div>
          </div>
          <div className="cai" />
          <div className="person">
            <div className="person1"></div>
            <div className="person2"></div>
            <div className="person3"></div>
          </div>
        </div>
      </div>
      <div className="food" ref={foodRef}></div>

    </Section>
  );
};
export default Make;
