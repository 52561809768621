function isScrollAtBottom(element) {
    return element.scrollHeight - element.scrollTop === element.clientHeight;

    //   const containerElement = document.getElementById('container');
    // const isAtBottom = isScrollAtBottom(containerElement);
    // console.log(isAtBottom); // true 或 false
}

function throttle(fn, interval) {
    var enterTime = 0; //触发的时间
    var gapTime = interval || 300; //间隔时间，如果interval不传，则默认300ms
    return function() {
        var context = this;
        var backTime = new Date(); //第一次函数return即触发的时间
        if (gapTime === 31000) {
            fn.call(context, arguments);
            enterTime = backTime; //赋值给第一次触发的时间，这样就保存了第二次触发的时间
            return
        }
        if (backTime - enterTime > gapTime) {
            fn.call(context, arguments);
            enterTime = backTime; //赋值给第一次触发的时间，这样就保存了第二次触发的时间
        }
    };
}
/*函数防抖*/
function debounce(fn, delay) {
    let timer;
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(context, args);
        }, delay || 300);
    };
}

function isWheelFn() { //规定时间调用
    let isWheel
    let oldTime = localStorage.getItem('oldTime') || '0',
        newTime = Date.now()
    let reduceTime = (newTime - oldTime) / 1000
    reduceTime >= 1 ? isWheel = true : isWheel = false
    return ({
        isWheel,
        newTime
    })
}

function isPageBottom() {
    return window.scrollY + window.innerHeight === document.documentElement.scrollHeight;
}


module.exports = {
    throttle: throttle,
    debounce: debounce,
    isWheelFn: isWheelFn,
    isPageBottom: isPageBottom
}