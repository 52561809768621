import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import SwiperCore, { Navigation as SwiperNavigation } from "swiper";
import styled from "styled-components";
import { debounce } from "../components/utils";
SwiperCore.use([SwiperNavigation]);


const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:flex-start;
  position: relative;
  font-size: 24px;
  flex: 1;
  letter-spacing: 2px;
  cursor: pointer;
  .swiper,p {
    width: 1200px;
    .swiper-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 120rem;
        height: 52.5rem;
        object-fit: cover;
        border-radius: 2.6rem;
      }

    }
  }
  p {
        margin-top: 3rem;
        line-height: 3.3rem;
        text-align: center;
      }
`;

const ToC = () => {
  const swiperRef = useRef(null);
  const navigate = useNavigate();

  const goNextPage = (e) => {
    const { activeIndex } = swiperRef.current;
    if (e.deltaY > 60) {
      console.log(activeIndex, "index");
      if (activeIndex === 2) {
        navigate("/product/food");
        return;
      }
      swiperRef.current.slideTo(activeIndex + 1);
    }
    if (e.deltaY < -60) {
      if (activeIndex === 0) {
        navigate("/tob");
        return;
      }
      swiperRef.current.slideTo(activeIndex - 1);
    }
  };

  return (
    <Section onWheel={debounce(goNextPage,300)}>
      <Swiper
        loop={true}
        navigation={{
          prevEl: ".custom-prev-button",
          nextEl: ".custom-next-button",
        }}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        className="swiper"
      >
        <SwiperSlide className="swiper-item">
          <img src={require("../staic/toc-01.png")} alt="" />
        </SwiperSlide  >
        <SwiperSlide className="swiper-item">
          <img src={require("../staic/toc-02.png")} alt="" />
        </SwiperSlide>
        <SwiperSlide  className="swiper-item">
          <img src={require("../staic/toc-03.png")} alt="" />
        </SwiperSlide>

      </Swiper>

      <p className="text">
      【TOC】于地铁站、高铁站、大型办公楼等应用场景、针对上班族快节奏生活早餐午餐没时间买、于上班族上班必经之地放置。<br/>吾点热食取餐柜，即购即取，即取即得、无需等候取完就走。
      </p>
      <img
        src={require("../staic/left.png")}
        className="pre"
        alt=""
        onClick={() => swiperRef.current.slidePrev()}
      />
      <img
        src={require("../staic/right.png")}
        alt=""
        className="next"
        onClick={() => swiperRef.current.slideNext()}
      />
    </Section>
  );
};
export default ToC;
