import { React, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation as SwiperNavigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { debounce } from "../components/utils";
SwiperCore.use([SwiperNavigation]);

const Section = styled.section`
  width: 100%;
  font-size: 2.4rem;
  letter-spacing: 0.2rem;
  flex: 1;
  display: flex;
  overflow-y: hidden;
  cursor: pointer;
  .swiper {
    width: 1200px;
    .swiper-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 120rem;
        height: 52.5rem;
        object-fit: cover;
        border-radius: 2.6rem;
      }
      .item-text {
        margin-top: 3rem;
        line-height: 3.3rem;
        text-align: center;
      }
    }
  }

`;
const ToB = () => {
  const swiperRef = useRef(null);
  const navigate = useNavigate();
  const goNextPage = (e) => {
    const { activeIndex } = swiperRef.current;
    if (e.deltaY > 60) {
      console.log(activeIndex, "index");
      if (activeIndex === 3) {
        navigate("/toc");
        return;
      }
      swiperRef.current.slideTo(activeIndex + 1);
    }
    if (e.deltaY < -60) {
      if (activeIndex === 0) {
        navigate("/business/qucan");
        return;
      }
      swiperRef.current.slideTo(activeIndex - 1);
    }
  };

  return (
    <Section onWheel={debounce(goNextPage, 300)}>
      <Swiper
        className="swiper"
        loop={true}
        navigation={{
          prevEl: ".custom-prev-button",
          nextEl: ".custom-next-button",
        }}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
      >
        <SwiperSlide className="swiper-item">
          <img src={require("../staic/tob-1.png")} alt="" />
          <p className="item-text">
          【TOB】酒店:针对经济型、舒适型酒店自营早餐90%亏损.不供应早餐服务体验更不上入住率低、外包门口小店供应不稳定售后无保障等一系列早餐经营困扰
            来提供优化酒店早餐方案，一站式解决所有早餐困扰。
          </p>
        </SwiperSlide>

        <SwiperSlide className="swiper-item">
          <img src={require("../staic/tob-2.png")} alt="" />
          <p className="item-text">
            【TOB】酒店:针对经济型、舒适型酒店自营早餐90%亏损.不供应早餐服务体验更不上入住率低、外包门口小店供应不稳定售后无保障等一系列早餐经营困扰
            来提供优化酒店早餐方案，一站式解决所有早餐困扰。
          </p>
        </SwiperSlide>
        <SwiperSlide className="swiper-item">
          <img src={require("../staic/tob-3.png")} alt="" />
          <p className="item-text">
          【TOB】酒店:针对经济型、舒适型酒店自营早餐90%亏损.不供应早餐服务体验更不上入住率低、外包门口小店供应不稳定售后无保障等一系列早餐经营困扰
            来提供优化酒店早餐方案，一站式解决所有早餐困扰。
          </p>
        </SwiperSlide>
        <SwiperSlide className="swiper-item">
          <img src={require("../staic/tob-4.png")} alt="" />
          <p className="item-text">
          【TOB】体检中心:体检中心早餐是必须配套项目，可人工制作和损耗成本过高体检人员时间不确定，针对此市场可用吾点自营早餐项目得到很好的解决
            大型办公公司;大型办公人员多的情况既要自营中晚餐还有自营早餐餐饮成过于大，外包早餐是更好的选择
          </p>
        </SwiperSlide>
      </Swiper>
      <img
        src={require("../staic/left.png")}
        className="pre"
        alt=""
        onClick={() => swiperRef.current.slidePrev()}
      />
      <img
        src={require("../staic/right.png")}
        alt=""
        className="next"
        onClick={() => swiperRef.current.slideNext()}
      />
    </Section>
  );
};
export default ToB;
